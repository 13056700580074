import { useContext, useState, useEffect } from "react";
//mui
import Box from "@mui/material/Box";
import { Grid, Container, Breadcrumbs, TextField, Typography } from "@mui/material";
import { Button, Select, MenuItem, InputLabel, FormControl, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
//icon
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import zhTW from "date-fns/locale/zh-TW";
import { format } from "date-fns";
import { useLocation, useParams, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AppContext } from "../AppContext";
import DeleteAlert from "../component/DeleteAlert";
import { Helmet } from "react-helmet";
const CustomTextField = styled(TextField)(({ theme }) => ({
  minWidth: "250px",
  height: "56px",
  "& label.MuiInputLabel-root": {
    color: "#aaa",
  },
  "& label.Mui-focused": {
    color: "var(--color-primary)",
  },
  "& .MuiInputBase-input": { padding: "0px 16px", color: "var(--color-on-surface)", height: "56px" },
  "& .MuiOutlinedInput-root": {
    color: "var(--color-on-surface)",
    "& fieldset": {
      borderColor: "#aaa",
    },
    "&:hover fieldset": {
      borderColor: "var(--color-on-surface)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-primary)",
    },
    "& textarea": {
      padding: "0px",
    },
  },
}));
function UncontrolledTextField(prop) {
  return (
    <Controller
      {...prop}
      // rules={{ maxLength: {value: 50,message: '文字長度超過50字'} }}
      render={({ field }) => (
        <CustomTextField
          {...field}
          InputLabelProps={{ size: "normal" }}
          multiline={prop.multiline}
          sx={{ width:"250px", ...prop.sx,  }}
          label={prop.label}
          variant="outlined"
          autoComplete="off"
          color="primary"
        />
      )}
    />
  );
}
function UncontrolledDatePicker(prop) {
  return (
    <Controller
      {...prop}
      render={({ field }) => (
        <DatePicker
          {...field}
          label={prop.label}
          openTo="day"
          views={["year", "month", "day"]}
          inputFormat="yyyy-MM-dd"
          renderInput={(params) => <TextField sx={{ width: "250px", "& fieldset": { borderColor: "rgb(192, 192, 192)" } }} {...params} />}
        />
      )}
    />
  );
}
function ControlledDatePicker(prop) {
  return (
    <Controller
      {...prop}
      render={({ field }) => (
        <DatePicker
          {...field}
          label={prop.label}
          openTo="day"
          views={["year", "month", "day"]}
          inputFormat="yyyy-MM-dd"
          renderInput={(params) => (
            <TextField
              sx={{ width: "250px", "& fieldset": { borderColor: "rgb(192, 192, 192)" } }}
              {...params}
              // value={prop.value}
              // onChange={prop.onChange}
            />
          )}
          value={prop.value}
          onChange={prop.onChange}
        />
      )}
    />
  );
}
const initialPet = {
  name: "",
  chipNumber: "",
  birthday: null,
  color: "",
  breed: "",
  gender: "",
};
function TradeEdit(props) {
  const appContext = useContext(AppContext);
  const [showSaveAlert, setShowSaveAlert, redirectPath, setRedirectPath] = useOutletContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm();
  const [displayDeleteBtn, setDisplayDeleteBtn] = useState("inherit");
  const [currentLocation, setCurrentLocation] = useState("");
  const [trade, setTrade] = useState({ trade: { payDate: null, receiveDate: null }, pet: initialPet, buyer: { birthday: null } });
  const [loading, setLoading] = useState(true);
  const [saveBtnConfig, setSaveBtnConfig] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  // const [chipNumberOpen, setChipNumberOpen] = useState(false);
  const [chipNumberLoading, setChipNumberLoading] = useState(false);
  const [pets, setPets] = useState([]);
  // const [currentPet, setCurrentPet] = useState(initialPet);

  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  let [querys] = useSearchParams();
  //watch input change
  useEffect(() => {
    const subscription = watch(() => (appContext.edited = true));
    return () => subscription.unsubscribe();
  }, [watch]);
  //fetch pet data; set different display for different page
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      if (location.pathname.indexOf("/tradelist/edit") >= 0) {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/trade/${params.tradeId}?storeId=${appContext.storeId}`, {
          headers: new Headers({
            Authorization: "Bearer " + appContext.idToken,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            if (data !== null) {
              setTrade(data);
              // setCurrentPet(data.pet);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error("Fetch trade error", error);
          });
        setDisplayDeleteBtn("inherit");
        setCurrentLocation("編輯");
        setSaveBtnConfig({
          method: "PUT",
          url: `${process.env.REACT_APP_API_URL}/api/v1/trade/${params.tradeId}?storeId=${appContext.storeId}`,
        });
      } else if (location.pathname.indexOf("/tradelist/create") >= 0) {
        setDisplayDeleteBtn("none");
        setCurrentLocation("新增");
        setLoading(false);
        setSaveBtnConfig({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/v1/trade?storeId=${appContext.storeId}`,
        });
      }
    }
  }, []);
  //when refresh but not saved, show alert
  useEffect(() => {
    function handleUnload(e) {
      if (appContext.edited) {
        e.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);
  function SaveAlert({ showSaveAlert, handleClose }) {
    return (
      <Dialog open={showSaveAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">{"尚未儲存，是否儲存?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">尚未儲存，是否儲存?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleSubmit(onSubmit)();
              handleClose();
            }}
            variant="contained"
            autoFocus
          >
            儲存
          </Button>
          <Button
            onClick={() => {
              appContext.edited = false;
              handleClose();
              var _redirectPath = redirectPath;
              setRedirectPath("");
              navigate(_redirectPath);
            }}
          >
            直接離開
          </Button>
          <Button onClick={handleClose}>繼續編輯</Button>
        </DialogActions>
      </Dialog>
    );
  }
  function isValidDate(data, property, name, errMessage) {
    if (data[property] instanceof Date && !isNaN(data[property])) {
      data[property] = format(data[property], "yyyy-MM-dd");
    } else if (typeof data[property] === "string" && data[property] !== "") {
      return false;
    } else if (data[property] === null) {
      data[property] = "";
    } else {
      setError(name, { type: "date", message: errMessage });
      return true;
    }
    // return d instanceof Date && !isNaN(d);
    return false;
  }
  const onSubmit = (data) => {
    // console.debug(data);
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      //normalize date
      var error = false;
      error |= isValidDate(data.trade, "payDate", "trade.payDate", "支付日期格式錯誤");
      error |= isValidDate(data.trade, "receiveDate", "trade.receiveDate", "受領時間日期格式錯誤");
      error |= isValidDate(data.pet, "birthday", "pet.birthday", "寵物生日日期格式錯誤");
      error |= isValidDate(data.buyer, "birthday", "buyer.birthday", "買受人生日日期格式錯誤");
      if (error) {
        return;
      }
      data.trade.balanceDue = parseInt(data.trade.balanceDue) || 0;
      data.trade.deposit = parseInt(data.trade.deposit) || 0;
      data.trade.totalPrice = parseInt(data.trade.totalPrice) || 0;
      //   console.debug("after normalize:", data);
      fetch(saveBtnConfig.url, {
        method: saveBtnConfig.method,
        body: JSON.stringify(data),
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((response) => {
          // console.debug(response);
          var from = querys.get("from");
          appContext.edited = false;
          if (redirectPath !== "") {
            var _redirectPath = redirectPath;
            setRedirectPath("");
            navigate(_redirectPath);
            return;
          }
          if (from === "tradelist") {
            navigate("/dashboard/tradelist", { replace: true });
            return;
          }
          navigate(-1);
          return;
        })
        .catch((error) => {
          console.error("Post trade error", error);
        });
    }
  };

  const handleDeleteTrade = () => {
    var petid = params.tradeId;
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/trade/${petid}?storeId=${appContext.storeId}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            var from = querys.get("from");
            appContext.edited = false;
            if (from === "tradelist") {
              navigate(-1, { replace: true });
            } else if (from === "tradedetail") {
              navigate(-2, { replace: true });
            }
          }
        })
        .catch((error) => {
          console.error("Delete trade error", error);
        });
    }
  };
  zhTW.options.weekStartsOn = 0;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
      <Container sx={{ pb: 3 }}>
        <Helmet>
          <title>買賣紀錄 - 寵理才</title>
        </Helmet>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
          <Grid item xs={4} sm={8} md={12}>
            <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon fontSize="medium" />}>
              <Link
                underline="hover"
                href="#"
                onClick={() => {
                  if (!appContext.edited) {
                    navigate("/dashboard/tradelist");
                  } else {
                    setRedirectPath("/dashboard/tradelist");
                    setShowSaveAlert(true);
                  }
                }}
              >
                <ReceiptLongIcon sx={{ mr: 1 }}/>
                買賣紀錄
              </Link>
              <Typography>{currentLocation}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
              <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                <Button variant="outlined" onClick={handleSubmit(onSubmit)} startIcon={<SaveIcon />}>
                  儲存
                </Button>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ display: displayDeleteBtn }}
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setShowDeleteAlert(true);
                  }}
                >
                  刪除
                </Button>
              </Box>
              <SaveAlert
                showSaveAlert={showSaveAlert}
                handleClose={() => {
                  setShowSaveAlert(false);
                }}
              />
              <DeleteAlert
                showDeleteAlert={showDeleteAlert}
                handleDelete={handleDeleteTrade}
                handleClose={() => {
                  setShowDeleteAlert(false);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Container
              sx={{
                // bgcolor: "var(--color-surface)",
                width: { xs: 282, sm: 564 },
                // padding: 2,
                borderRadius: "8px",
              }}
              disableGutters
            >
              {Object.values(errors).map((secError) =>
                Object.values(secError).map((error) => (
                  <Alert key={error.message} severity="error" sx={{ mb: 2 }}>
                    {error?.message}
                  </Alert>
                ))
              )}
              {loading ? (
                <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="rectangular" width={210} height={118} />
                </Box>
              ) : (
                <form
                  id="trade-edit-form"
                  style={{
                    display: "flex",
                    gap: "16px",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ color: "var(--color-primary)", width: "100%" }}> 交易資訊 </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      flexWrap: "wrap",
                      //   alignContent: "center",
                      borderRadius: "8px",
                      bgcolor: "var(--color-surface)",
                      p: 2,
                      width: "100%",
                    }}
                  >
                    <UncontrolledTextField
                      name="trade.totalPrice"
                      label="總價"
                      control={control}
                      defaultValue={trade.trade.totalPrice}
                      rules={{ maxLength: { value: 10, message: "總價長度超過10位數" } }}
                    />
                    <UncontrolledDatePicker
                      name="trade.payDate"
                      label="支付日期"
                      control={control}
                      defaultValue={!!trade.trade.payDate ? trade.trade.payDate : null}
                    />
                    <UncontrolledTextField
                      name="trade.deposit"
                      label="訂金"
                      control={control}
                      defaultValue={trade.trade.deposit}
                      rules={{ maxLength: { value: 10, message: "訂金文字長度超過10位數" } }}
                    />
                    <UncontrolledTextField
                      name="trade.balanceDue"
                      label="餘款期限"
                      control={control}
                      defaultValue={trade.trade.balanceDue}
                      rules={{ maxLength: { value: 3, message: "餘款期限文字長度超過3位數" } }}
                    />
                    <UncontrolledDatePicker
                      name="trade.receiveDate"
                      label="受領時間"
                      control={control}
                      defaultValue={!!trade.trade.receiveDate ? trade.trade.receiveDate : null}
                    />
                  </Box>
                  <Typography sx={{ color: "var(--color-primary)", width: "100%" }}> 寵物資訊 </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      flexWrap: "wrap",
                      //   alignContent: "center",
                      borderRadius: "8px",
                      bgcolor: "var(--color-surface)",
                      p: 2,
                      width: "100%",
                    }}
                  >
                    <UncontrolledTextField
                      name="pet.species"
                      label="寵物種類"
                      control={control}
                      defaultValue={!!trade.pet.species ? trade.pet.species : "貓"}
                      rules={{ maxLength: { value: 10, message: "寵物種類文字長度超過10位數" } }}
                    />
                    <Controller
                      render={({ field: { ref, onChange, ...field } }) => (
                        <Autocomplete
                          freeSolo
                          options={pets}
                          // isOptionEqualToValue={(option, value) => option.chipNumber === value.chipNumber}
                          onOpen={() => {
                            if (!pets.length) {
                              setChipNumberLoading(true);
                              fetch(`${process.env.REACT_APP_API_URL}/api/v1/pets/tradeInfo?storeId=${appContext.storeId}`, {
                                method: "GET",
                                headers: new Headers({
                                  Authorization: "Bearer " + appContext.idToken,
                                }),
                              })
                                .then((response) => {
                                  if (response.ok) {
                                    return response.json();
                                  }
                                })
                                .then((data) => {
                                  if (data !== null) {
                                    setPets(data);
                                  }
                                  setChipNumberLoading(false);
                                })
                                .catch((error) => {
                                  console.error("Fetch pet list error", error);
                                  setChipNumberLoading(false);
                                });
                            }
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": { pt: "0px", pb: "0px" },
                            "& .MuiOutlinedInput-root .MuiAutocomplete-input": { pt: "0px", pb: "0px" },
                          }}
                          getOptionLabel={(option) => (!!option.chipNumber ? option.chipNumber : "")}
                          // renderOption={renderOption}
                          // loading={chipNumberLoading}
                          // defaultValue={currentPet.chipNumber}
                          renderInput={(params) => {
                            // console.log("params", params)
                            return (
                              <CustomTextField
                                {...params}
                                label="晶片號碼"
                                variant="outlined"
                                autoComplete="off"
                                InputLabelProps={{ size: "normal" }}
                                color="primary"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {chipNumberLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            );
                          }}
                          inputValue={trade.pet.chipNumber}
                          onInputChange={(e, value) => {
                            console.log("value:", value);
                            onChange(value === null ? "" : value);
                            setTrade({ ...trade, pet: { ...trade.pet, chipNumber: value } });
                            // setCurrentPet({ ...currentPet, chipNumber: value });
                          }}
                          // value={currentPet.chipNumber}
                          onChange={(e, data) => {
                            console.log("data", data);
                            onChange(data === null ? "" : data.chipNumber);
                            if (data !== null) {
                              setValue("pet.name", data.name);
                              setValue("pet.birthday", data.birthday);
                              setValue("pet.gender", data.gender);
                              setValue("pet.color", data.color);
                              setValue("pet.breed", data.breed);
                              setTrade({ ...trade, pet: data });
                              // setCurrentPet(data);
                            } else {
                              setValue("pet.name", "");
                              setValue("pet.birthday", null);
                              setValue("pet.gender", "");
                              setValue("pet.color", "");
                              setValue("pet.breed", "");
                              setTrade({ ...trade, pet: initialPet });
                              // setCurrentPet(initialPet);
                            }
                          }}
                        />
                      )}
                      rules={{ maxLength: { value: 14, message: "晶片號碼長度超過14位數" } }}
                      defaultValue={trade.pet.chipNumber}
                      value={trade.pet.chipNumber}
                      name="pet.chipNumber"
                      control={control}
                    />
                    <UncontrolledTextField
                      name="pet.name"
                      label="寵物名稱"
                      control={control}
                      value={trade.pet.name}
                      defaultValue={trade.pet.name}
                      rules={{ maxLength: { value: 20, message: "寵物名稱長度超過20字" } }}
                    />
                    <UncontrolledDatePicker
                      name="pet.birthday"
                      label="生日"
                      control={control}
                      defaultValue={!!trade.pet.birthday ? trade.pet.birthday : null}
                      value={trade.pet.birthday}
                    />
                    <Controller
                      name="pet.gender"
                      control={control}
                      defaultValue={!!trade.pet.gender ? trade.pet.gender : "公"}
                      value={trade.pet.gender}
                      render={({ field }) => (
                        <FormControl>
                          <InputLabel>性別</InputLabel>
                          <Select
                            {...field}
                            id="demo-simple-select"
                            label="性別"
                            sx={{ width: "250px", "& fieldset": { borderColor: "rgb(192, 192, 192)" } }}
                          >
                            <MenuItem value={"公"}>公</MenuItem>
                            <MenuItem value={"母"}>母</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                    <UncontrolledTextField
                      name="pet.color"
                      label="顏色"
                      control={control}
                      defaultValue={!!trade.pet.color ? trade.pet.color : ""}
                      value={trade.pet.color}
                      rules={{ maxLength: { value: 20, message: "顏色長度超過20字" } }}
                    />
                    <UncontrolledTextField
                      name="pet.breed"
                      label="品種"
                      control={control}
                      defaultValue={!!trade.pet.breed ? trade.pet.breed : ""}
                      rules={{ maxLength: { value: 20, message: "品種長度超過20字" } }}
                    />
                  </Box>
                  <Typography sx={{ color: "var(--color-primary)", width: "100%" }}> 買受人資訊 </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      flexWrap: "wrap",
                      borderRadius: "8px",
                      bgcolor: "var(--color-surface)",
                      p: 2,
                      width: "100%",
                    }}
                  >
                    <UncontrolledTextField
                      name="buyer.name"
                      label="買受人"
                      control={control}
                      defaultValue={trade.buyer.name}
                      rules={{ maxLength: { value: 20, message: "買受人名稱長度超過20字" } }}
                    />
                    <UncontrolledTextField
                      name="buyer.phone"
                      label="電話"
                      control={control}
                      defaultValue={trade.buyer.phone}
                      rules={{ maxLength: { value: 20, message: "電話長度超過20字" } }}
                    />
                    <UncontrolledDatePicker
                      name="buyer.birthday"
                      label="生日"
                      control={control}
                      defaultValue={!!trade.buyer.birthday ? trade.buyer.birthday : null}
                    />
                    <UncontrolledTextField name="buyer.idNumber" label="身分證字號" control={control} defaultValue={trade.buyer.idNumber} />
                    <UncontrolledTextField
                      name="buyer.address"
                      label="地址"
                      sx={{ width: { xs: "250px", sm: "516px" }, height: "auto" }}
                      control={control}
                      multiline={true}
                      defaultValue={trade.buyer.address}
                      rules={{ maxLength: { value: 100, message: "地址長度超過100字" } }}
                    />
                  </Box>
                </form>
              )}
            </Container>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
}

export default TradeEdit;
