import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { AppContext } from "../AppContext";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Helmet } from "react-helmet";
import GenerateContract from "../component/Contract";
const tableCellStyle = {
  fontSize: "16px",
  color: "var(--color-on-surface)",
  //   borderBottom: "1px solid var(--color-on-surface)",
  borderBottom: "1px solid #888",
  padding: 2,
};
const tableCellSectionStyle = {
  fontSize: "16px",
  color: "var(--color-primary)",
  borderBottom: "1px solid var(--color-primary)",
  padding: 2,
};

function createData(key, value) {
  return { key, value };
}

// const rows = [
// createData("名字", "毛毛"),
// createData("晶片號碼", "11111111111111"),
// createData("品種", "波斯貓"),
// createData("生日", "2022/07/01"),
// createData("年齡", "1"),
// createData("性別", "母"),
// createData("顏色", "銀色"),
// createData("父晶片號", "22222222222222"),
// createData("母晶片號", "33333333333333"),
// createData("是否進口", "是"),
// createData("進口金額", "20000"),
// createData("待產日", "2022/12/01"),
// createData("備註", "我是備註"),
// ];

function TradeDetail() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const [trade, setTrade] = useState({});
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  let params = useParams();
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/trade/${params.tradeId}?storeId=${appContext.storeId}`, {
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((tradeResult) => {
          if (tradeResult !== null) {
            var tempRows = [];
            tempRows.push(createData("交易資訊", "section-header"));
            tempRows.push(createData("總價", tradeResult.trade.totalPrice));
            tempRows.push(createData("支付日期", tradeResult.trade.payDate));
            tempRows.push(createData("訂金", tradeResult.trade.deposit));
            tempRows.push(createData("餘款期限", tradeResult.trade.balanceDue));
            tempRows.push(createData("受領時間", tradeResult.trade.receiveDate));

            tempRows.push(createData("寵物資訊", "section-header"));
            tempRows.push(createData("寵物種類", tradeResult.pet.species));
            tempRows.push(createData("寵物名稱", tradeResult.pet.name));
            tempRows.push(createData("晶片號碼", tradeResult.pet.chipNumber));
            tempRows.push(createData("出生日期", tradeResult.pet.birthday));
            tempRows.push(createData("性別", tradeResult.pet.gender));
            tempRows.push(createData("顏色", tradeResult.pet.color));
            tempRows.push(createData("品種", tradeResult.pet.breed));

            tempRows.push(createData("買受人資訊", "section-header"));
            tempRows.push(createData("買受人", tradeResult.buyer.name));
            tempRows.push(createData("電話", tradeResult.buyer.phone));
            tempRows.push(createData("身分證", tradeResult.buyer.idNumber));
            tempRows.push(createData("生日", tradeResult.buyer.birthday));
            tempRows.push(createData("住址", tradeResult.buyer.address));
			setTrade(tradeResult)
            setRows(tempRows);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Fetch trade error", error);
        });
    }
    // setDueDates([new Date(2022, 6, 4), new Date(2022, 6, 2), new Date(2022, 7, 1)]); // for testing
  }, []);
  function handleEdit() {
    navigate(`/dashboard/tradelist/edit/${params.tradeId}?from=tradedetail`);
  }
  function handleDownloadContract() {
    GenerateContract(trade, appContext.store);
  }

  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>買賣紀錄檢視 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon />}>
            <Link underline="hover" href="/dashboard/tradelist">
              <ReceiptLongIcon sx={{ mr: 1 }}/>
              買賣紀錄
            </Link>
            <Typography>檢視</Typography>
          </Breadcrumbs>
        </Grid>
        {loading ? (
          <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={118} />
          </Box>
        ) : (
          <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
              <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEdit}>
                  修改
                </Button>
                <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleDownloadContract}>
                  下載契約
                </Button>
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "var(--color-surface)",
                borderRadius: "8px",
                maxWidth: "500px",
                minWidth: "300px",
                margin: "0px auto",
              }}
            >
              <Table sx={{}} aria-label="simple table">
                <TableBody>
                  {rows.map((row) => {
                    var style = {};
                    var rowValue = "";
                    if (row.value === "section-header") {
                      style = tableCellSectionStyle;
                      rowValue = "";
                    } else {
                      style = tableCellStyle;
                      rowValue = row.value;
                    }
                    return (
                      <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell scope="row" sx={style}>
                          {row.key}
                        </TableCell>
                        <TableCell sx={style}>{rowValue}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default TradeDetail;
