import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function DeleteAlert({ showDeleteAlert, handleClose, handleDelete }) {
  return (
    <Dialog open={showDeleteAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">是否確定要刪除?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleDelete();
            handleClose();
          }}
        >
          是
        </Button>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            handleClose();
          }}
        >
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAlert;
