import { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, useNavigate } from "react-router-dom";
import LeftMenu from "./component/LeftMenu";
import { AppContext } from "./AppContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const drawerWidth = 240;
const appBarHeight = 40;

function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");
  const [storeName, setStoreName] = useState("店家名稱");
  let navigate = useNavigate();
  const appContext = useContext(AppContext);
  const auth = getAuth(appContext.app);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const verifyUser = (user) => {
    if (!user.emailVerified) {
      navigate("/login");
      return;
    } else {
      user.getIdTokenResult(true).then((token) => {
        if ("demo" in token.claims && token.claims.demo) {
        } else {
          if ("paid" in token.claims && token.claims.paid) {
          } else {
            navigate("/payment");
            return;
          }
        }
        if ("role" in token.claims && token.claims.role.includes("admin")) {
          appContext.signedIn = true;
        } else {
          navigate("/login");
          return;
        }
        if ("storeId" in token.claims && token.claims.storeId !== "") {
          appContext.storeId = token.claims.storeId;
          setShowContent(true);
        } else {
          navigate("/login");
          return;
        }
      });
    }
  };
  useEffect(() => {
    document.querySelector("body").style.background = "var(--color-background)";
    onAuthStateChanged(auth, (user) => {
      if (user) {
        verifyUser(user);
        user.getIdToken().then((idToken) => {
          appContext.idToken = idToken;
          appContext.user = user;
          // console.debug(user)
        });
      } else {
        // console.debug("signed out");
        navigate("/login");
      }
    });
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {showContent && (
        <>
          <LeftMenu
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            setShowSaveAlert={setShowSaveAlert}
            setRedirectPath={setRedirectPath}
            storeName={storeName}
            setStoreName={setStoreName}
          />
          <AppBar
            position="fixed"
            sx={{
              width: { lg: `calc(100% - ${drawerWidth}px)` },
              ml: { lg: `${drawerWidth}px` },
              display: { lg: "none" },
              height: `${appBarHeight}px`,
              bgcolor: "var(--color-surface)",
            }}
          >
            <Toolbar sx={{ height: `${appBarHeight}px`, minHeight: { xs: `${appBarHeight}px` } }}>
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 0, width: { md: `calc(100% - ${drawerWidth}px)` }, mt: { xs: "64px", lg: 3 }, overflow: "hidden" }}
          >
            <Outlet context={[showSaveAlert, setShowSaveAlert, redirectPath, setRedirectPath, setStoreName]} />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Dashboard;
