import { Box, Typography } from "@mui/material";
function ReturnPolicy() {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        退換貨方式
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.如需暫停、取消訂閱，請來信 support@budylon.com 為您服務
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.退款以一個月為單位，往後推算剩餘訂閱時長，舉例：訂閱12個月，已使用2個月又1天，將退款剩餘9個月金額
      </Typography>
      <Typography variant="h6" gutterBottom>
       【無法辦理退款的情況】
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.匯款帳號填寫有誤，將無法辦理退款。
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.紙本發票未寄回
      </Typography>
      <Typography variant="h6" gutterBottom>
       【退款流程】
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.來信至 support@budylon.com ，並提供退款帳號、分行名稱、戶名
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.寄回發票
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.請等待14個工作日，核對相關資料無誤，會將退款金額退回您指定銀行帳戶
      </Typography>
    </Box>
  );
}
export default ReturnPolicy;
