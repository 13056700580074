import { useContext, useEffect, useState } from "react";
import {Box, Link, Container, Grid ,Typography ,Avatar , Breadcrumbs } from "@mui/material";
import {Table, TableBody, TableCell, TableContainer ,TableRow, Button,  Paper, Skeleton } from "@mui/material";
//mui icon
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";
import LanIcon from "@mui/icons-material/Lan";
import { useNavigate, useParams } from "react-router-dom";
import catIcon from "./cat-icon.png";
import { Helmet } from "react-helmet";
import { AppContext } from "../AppContext";

const tableCellStyle = {
  fontSize: "16px",
  color: "var(--color-on-surface)",
  borderBottom: "1px solid #888",
  padding: 2,
};

function createData(key, value) {
  return { key, value };
}

// const rows = [
// createData("名字", "毛毛"),
// createData("晶片號碼", "11111111111111"),
// createData("品種", "波斯貓"),
// createData("生日", "2022/07/01"),
// createData("年齡", "1"),
// createData("性別", "母"),
// createData("顏色", "銀色"),
// createData("父晶片號", "22222222222222"),
// createData("母晶片號", "33333333333333"),
// createData("是否進口", "是"),
// createData("進口金額", "20000"),
// createData("待產日", "2022/12/01"),
// createData("備註", "我是備註"),
// ];

function PetDetail() {
  const appContext = useContext(AppContext);
  const [photoUrl, setPhotoUrl] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  let params = useParams();
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/${params.petid}?storeId=${appContext.storeId}`, {
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((petResult) => {
          if (petResult !== null) {
            var tempRows = [];
            tempRows.push(createData("名字", petResult.name));
            tempRows.push(createData("晶片號碼", petResult.chipNumber));
            tempRows.push(createData("品種", petResult.breed));
            tempRows.push(createData("生日", petResult.birthday));
            
            var showYear = "";
            if (petResult.birthday !== "") {
              
              let today = new Date();
              let birthDate = petResult.birthday;
              birthDate = birthDate.split('-');
              let todayArr = [today.getFullYear(), today.getMonth()+1, today.getDate()];

              let age = todayArr.map((val, index) =>{
                console.log("today:" + today.getMonth())
                console.log( val + ":" + birthDate[index]);
                return val - birthDate[index];
              })

              if (age[2] < 0) {
               
                let curMonth = new Date(todayArr[0], todayArr[1], 0)
                age[1]--
                age[2] += curMonth.getDate()
              }
              if (age[1] < 0) {
                  age[0]--
                  age[1] += 12
              }
              showYear = age[0] + "年" + age[1] + "月";
            }
            tempRows.push(createData("年齡", showYear));
            tempRows.push(createData("性別", petResult.gender));
            tempRows.push(createData("顏色", petResult.color));
            tempRows.push(createData("父晶片號", petResult.fatherChipNumber));
            tempRows.push(createData("母晶片號", petResult.motherChipNumber));
            tempRows.push(createData("是否進口", petResult.import ? "是" : "否"));
            tempRows.push(createData("進口金額", petResult.importPrice));
            tempRows.push(createData("狂犬病接種日", petResult.rabiesVaccination));
            tempRows.push(createData("預防針接種日", petResult.generalVaccination));
            tempRows.push(createData("待產日", petResult.dueDate));
            tempRows.push(createData("備註", petResult.comment));
            setRows(tempRows);
            setLoading(false);
            if (petResult.photoUrl !== "") {
              fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/photo/${petResult.photoUrl}`, {
                headers: new Headers({
                  Authorization: "Bearer " + appContext.idToken,
                }),
              })
                .then((response) => {
                  if (response.ok) {
                    return response.blob();
                  }
                })
                .then(function (myBlob) {
                  var objectURL = URL.createObjectURL(myBlob);
                  setPhotoUrl(objectURL);
                })
                .catch(function (error) {
                  console.error("fetch pet photo error: ", error.message);
                });
            }
          }
        })
        .catch((error) => {
          console.error("Fetch due date error", error);
        });
    }
    // setDueDates([new Date(2022, 6, 4), new Date(2022, 6, 2), new Date(2022, 7, 1)]); // for testing
  }, []);
  function handleEdit() {
    navigate(`/dashboard/petlist/edit/${params.petid}?from=petdetail`);
  }

  function handleGenealogy() {
    navigate(`/dashboard/petlist/genealogy/${params.petid}`);
  }

  return (
    <Container sx={{ pb: 3 }}>
       <Helmet>
        <title>寵物檢視 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon />}>
            <Link underline="hover" href="/dashboard/petlist">
              <FormatListBulletedIcon sx={{mr: 1}}/>
              寵物清單
            </Link>
            <Typography>檢視</Typography>
          </Breadcrumbs>
        </Grid>
        {loading ? (
          <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={118} />
          </Box>
        ) : (
          <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
              <Avatar
                alt="cat profile"
                src={photoUrl ? photoUrl : catIcon}
                sx={{ width: { xs: 80, sm: 100, lg: 120 }, height: { xs: 80, sm: 100, lg: 120 } }}
              ></Avatar>
              <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEdit}>
                  修改
                </Button>
                <Button variant="outlined" startIcon={<LanIcon />} onClick={handleGenealogy}>
                  族譜
                </Button>
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "var(--color-surface)",
                borderRadius: "8px",
                maxWidth: "500px",
                minWidth: "300px",
                margin: "0px auto",
              }}
            >
              <Table sx={{}} aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" sx={tableCellStyle}>
                        {row.key}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default PetDetail;
