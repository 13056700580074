import { useContext, useState, useEffect } from "react";
//mui
import { Box, Grid, Container, Breadcrumbs, Skeleton, Alert, TextField, Button } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/material/styles";
import { AppContext } from "../AppContext";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";
const CustomTextField = styled(TextField)(({ theme }) => ({
  minWidth: "250px",
  height: "56px",
  "& label.MuiInputLabel-root": {
    color: "#aaa",
  },
  "& label.Mui-focused": {
    color: "var(--color-primary)",
  },
  "& .MuiInputBase-input": { padding: "0px 16px", color: "var(--color-on-surface)", height: "56px" },
  "& .MuiOutlinedInput-root": {
    color: "var(--color-on-surface)",
    "& fieldset": {
      borderColor: "#aaa",
    },
    "&:hover fieldset": {
      borderColor: "var(--color-on-surface)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-primary)",
    },
    "& textarea": {
      padding: "0px",
    },
  },
}));
function ControlledTextField(prop) {
  return (
    <Controller
      name={prop.name}
      control={prop.control}
      defaultValue={prop.defaultValue}
      rules={prop.rules}
      render={({ field }) => (
        <CustomTextField
          {...field}
          multiline={prop.multiline}
          sx={{ width: "250px", ...prop.sx }}
          label={prop.label}
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{ size: "normal" }}
          color="primary"
        />
      )}
    />
  );
}

function Store() {
  const appContext = useContext(AppContext);
  const [showSaveAlert, setShowSaveAlert, redirectPath, setRedirectPath, setStoreName] = useOutletContext();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const [saveBtn, setSaveBtn] = useState("");
  const [store, setStore] = useState({});
  const [loading, setLoading] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  let navigate = useNavigate();
  //watch input change
  useEffect(() => {
    const subscription = watch(() => (appContext.edited = true));
    return () => subscription.unsubscribe();
  }, [watch]);

  //fetch store data
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/store/${appContext.storeId}`, {
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data !== null) {
            setStore(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Fetch store error", error);
        });
      setSaveBtn({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/api/v1/store/${appContext.storeId}`,
      });
    }
  }, []);

  function SaveAlert({ showSaveAlert, handleClose }) {
    return (
      <Dialog open={showSaveAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">{"尚未儲存，是否儲存?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">尚未儲存，是否儲存?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleSubmit(onSubmit)();
              handleClose();
            }}
            variant="contained"
            autoFocus
          >
            儲存
          </Button>
          <Button
            onClick={() => {
              appContext.edited = false;
              handleClose();
              var _redirectPath = redirectPath;
              setRedirectPath("");
              navigate(_redirectPath);
            }}
          >
            直接離開
          </Button>
          <Button onClick={handleClose}>繼續編輯</Button>
        </DialogActions>
      </Dialog>
    );
  }
  const onSubmit = (data) => {
    // console.debug(data);
    setShowSuccessAlert(false);
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(saveBtn.url, {
        method: saveBtn.method,
        body: JSON.stringify(data),
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          if (response.ok) {
            appContext.edited = false;
            appContext.store = data;
            setStoreName(data.name);
            setShowSuccessAlert(true);
            if (redirectPath !== "") {
              var _redirectPath = redirectPath;
              setRedirectPath("");
              navigate(_redirectPath);
              return;
            }
          } else if (response.status === 404) {
            response.json().then((data) => {
              setError({ state: true, msg: data.msg });
            });
          }
        })
        .catch((error) => {
          console.error("Post store error", error);
        });
    }
  };
  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>商店管理 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon fontSize="medium" />}>
            <Link underline="none">
              <FeaturedPlayListIcon sx={{ mr: 1 }} />
              商店管理
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
              <Button variant="outlined" onClick={handleSubmit(onSubmit)} startIcon={<SaveIcon />}>
                儲存
              </Button>
            </Box>
            <SaveAlert
              showSaveAlert={showSaveAlert}
              handleClose={() => {
                setShowSaveAlert(false);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Container
            sx={{
              bgcolor: "var(--color-surface)",
              width: { xs: 282, sm: 564 },
              padding: 2,
              borderRadius: "8px",
            }}
          >
            {showSuccessAlert && (
              <Alert severity="success" sx={{ mb: 2 }}>
                儲存成功
              </Alert>
            )}
            {Object.values(errors).map((error) => (
              <Alert key={error.message} severity="error" sx={{ mb: 2 }}>
                {error?.message}
              </Alert>
            ))}
            {loading ? (
              <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={210} height={118} />
              </Box>
            ) : (
              <form
                id="store-edit-form"
                style={{
                  display: "flex",
                  gap: "16px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <ControlledTextField
                  name="name"
                  label="商店名稱"
                  control={control}
                  defaultValue={!!store?.name ? store.name : ""}
                  rules={{ maxLength: { value: 20, message: "商店名稱長度超過20字" } }}
                />
                <ControlledTextField
                  name="ownerName"
                  label="負責人姓名"
                  control={control}
                  defaultValue={!!store?.ownerName ? store.ownerName : ""}
                  rules={{ maxLength: { value: 20, message: "負責人姓名長度超過20字" } }}
                />

                <ControlledTextField
                  name="phone"
                  label="電話"
                  control={control}
                  defaultValue={!!store?.phone ? store.phone : ""}
                  rules={{ maxLength: { value: 20, message: "電話長度超過20字" } }}
                />
                <ControlledTextField
                  name="license"
                  label="許可證字號"
                  control={control}
                  defaultValue={!!store?.license ? store.license : ""}
                  rules={{ maxLength: { value: 30, message: "許可證字號長度超過30字" } }}
                />
                <ControlledTextField
                  name="registration"
                  label="公司登記證號"
                  control={control}
                  defaultValue={!!store?.registration ? store.registration : ""}
                  rules={{ maxLength: { value: 30, message: "公司登記證號長度超過30字" } }}
                />
                <ControlledTextField
                  name="url"
                  label="公司網址"
                  control={control}
                  defaultValue={!!store?.url ? store.url : ""}
                  rules={{ maxLength: { value: 200, message: "公司網址長度超過200字" } }}
                />

                <ControlledTextField
                  name="address"
                  label="地址"
                  sx={{ width: "516px", height: "auto" }}
                  control={control}
                  multiline={true}
                  defaultValue={!!store?.address ? store.address : ""}
                  rules={{ maxLength: { value: 100, message: "地址長度超過100字" } }}
                />
              </form>
            )}
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Store;
