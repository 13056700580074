import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./home/Main";
import Info from "./home/Info";
import TermsOfService from "./home/TermsOfService";
import Privacy from "./home/Privacy";
import LoginPage from "./LoginPage";
import Dashboard from "./Dashboard";
import Payment from "./home/Payment";
import ReturnPolicy from "./home/ReturnPolicy";
import ForgetPassword from "./ForgetPassword";
import PetList from "./dashboard/PetList";
import HomePage from "./dashboard/HomePage";
import PetDetail from "./dashboard/PetDetail";
import PetEdit from "./dashboard/PetEdit";
import CoupleList from "./dashboard/CoupleList";
import CoupleDetail from "./dashboard/CoupleDetail";
import CoupleEdit from "./dashboard/CoupleEdit";
import Genealogy from "./dashboard/Genealogy";
import Store from "./dashboard/Store";
import User from "./dashboard/User";
import TradeList from "./dashboard/TradeList";
import TradeEdit from "./dashboard/TradeEdit";
import TradeDetail from "./dashboard/TradeDetail";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContext, BreadcrumbsStyle } from "./AppContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_APP_ID}`,
  measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ef6c00",
    },
    secondary: {
      main: "#0083EF",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "'Noto Sans TC', sans-serif",
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AppContext.Provider
    value={{
      signedIn: false,
      BreadcrumbsStyle: BreadcrumbsStyle,
      firebaseApp: app,
      storeId: "",
      idToken: "",
      edited: false,
      store: {},
    }}
  >
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/info" element={<Info />}>
            <Route index element={<TermsOfService />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="return-policy" element={<ReturnPolicy />} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="payment" element={<Payment />} />
          <Route path="login/forgetpassword" element={<ForgetPassword />} />
          <Route path="dashboard" element={<Dashboard />}>
            <Route index element={<HomePage />} />
            <Route path="home" element={<HomePage />} />
            <Route path="petlist" element={<PetList />} />
            <Route path="petlist/:petid" element={<PetDetail />} />
            <Route path="petlist/genealogy/:petid" element={<Genealogy />} />
            <Route path="petlist/edit/:petid" element={<PetEdit />} />
            <Route path="petlist/create" element={<PetEdit />} />
            <Route path="couplelist" element={<CoupleList />} />
            <Route path="couplelist/:coupleid" element={<CoupleDetail />} />
            <Route path="couplelist/edit/:coupleid" element={<CoupleEdit />} />
            <Route path="couplelist/createchild" element={<PetEdit />} />
            <Route path="couplelist/create" element={<CoupleEdit />} />
            <Route path="store" element={<Store />} />
            <Route path="user" element={<User />} />
            <Route path="tradelist" element={<TradeList />} />
            <Route path="tradelist/:tradeId" element={<TradeDetail />} />
            <Route path="tradelist/create" element={<TradeEdit />} />
            <Route path="tradelist/edit/:tradeId" element={<TradeEdit />} />
          </Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </AppContext.Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
