import { useState, useContext, useEffect } from "react";
import {Box, Link, Container, Grid ,Typography ,IconButton , Breadcrumbs } from "@mui/material";
import {Table, TableBody, TableCell, TableContainer ,TableHead ,TableRow , TablePagination } from "@mui/material";
import {Button, TextField, Paper, Menu, MenuItem, ListItemText, ListItemIcon, Skeleton} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import PetPhotoAvatar from "../component/PetPhotoAvatar";
import { Helmet } from "react-helmet";
import DeleteAlert from "../component/DeleteAlert";

function profile(src, name, id) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        color: "var(--color-on-surface)",
      }}
    >
      <PetPhotoAvatar src={src} name={name} id={id} />
      <Typography sx={{ fontSize: "16px" }}>{name}</Typography>
    </Box>
  );
}

function createData(id, name, profile, chipNumber, gender, age) {
  return { id, name, profile, chipNumber, gender, age };
}

// const rows = [
//   createData(1, profile(catIcon, "小花"), "11111111111111", "公", "1"),
//   createData(2, profile(catIcon, "小花"), "22222222222222", "公", "1"),
//   createData(3, profile(catIcon, "小花"), "33333333333333", "公", "1"),
//   createData(4, profile(catIcon, "小花"), "44444444444444", "公", "1"),
//   createData(5, profile(catIcon, "小花"), "55555555555555", "公", "1"),
//   createData(6, profile(catIcon, "小花"), "66666666666666", "公", "1"),
// ];
const tableCellStyle = {
  fontSize: "16px",
  color: "var(--color-on-surface)",
  borderBottom: "1px solid var(--color-primary)",
  padding: { xs: 2 },
  whiteSpace: "nowrap",
};

const tablePaginationStyle = {
  height: "40px",
  // bgcolor:"var(--color-surface)",
  "& .MuiTablePagination-spacer": {
    flex: "0 1 auto",
  },
  "& .MuiTablePagination-toolbar": {
    height: "40px",
    minHeight: "40px",
    justifyContent: "flex-end",
  },
  "& .MuiTablePagination-selectLabel": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiInputBase-root": {
    marginRight: { xs: "16px", md: "32px" },
  },
  "& .MuiTablePagination-select": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    lineHeight: "32px",
    padding: "0px",
  },
  "& .MuiTablePagination-selectIcon": {
    color: "var(--color-on-surface)",
    fontSize: "24px",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiTablePagination-actions": {
    color: "var(--color-on-surface)",
    ml: { xs: "8px", md: "16px" },
  },
  "& .MuiTablePagination-actions .MuiSvgIcon-root": {
    fontSize: "24px",
  },
};

function PetList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = useState(matches ? 10 : 5);
  const [page, setPage] = useState(0);
  const [currentPetId, setCurrentPetId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [unfilteredRows, setUnfilteredRows] = useState([]);
  const appContext = useContext(AppContext);
  let navigate = useNavigate();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/pets?storeId=${appContext.storeId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data !== null) {
            var tempRows = [];
            for (var pet of data) {
              var showYear = "";
              if (pet.birthday !== "") {
                let today = new Date();
                let birthDate = pet.birthday;
                birthDate = birthDate.split('-');
                // getMonth 0(Jan) ~ 11(Dec)
                let todayArr = [today.getFullYear(), today.getMonth()+1, today.getDate()];

                let age = todayArr.map((val, index) =>{
                  return val - birthDate[index];
                })

                if (age[2] < 0) {
                
                  let curMonth = new Date(todayArr[0], todayArr[1], 0)
                  age[1]--
                  age[2] += curMonth.getDate()
                }
                if (age[1] < 0) {
                    age[0]--
                    age[1] += 12
                }
                showYear = age[0] + "年" + age[1] + "個月";
              }
              tempRows.push(createData(pet.id, pet.name, profile(pet.photoUrl, pet.name, pet.id), pet.chipNumber, pet.gender, showYear));
            }
            setUnfilteredRows(tempRows);
            setRows(tempRows);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Fetch pet list error", error);
          setLoading(false);
        });
    }
  }, []);

  const handleMenuClick = (event, id) => {
    setCurrentPetId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setCurrentPetId(null);
    setAnchorEl(null);
  };
  const handleViewPet = () => {
    var id = currentPetId;
    setCurrentPetId(null);
    setAnchorEl(null);
    navigate(`/dashboard/petlist/${id}`);
  };
  const handleEditPet = () => {
    var id = currentPetId;
    setCurrentPetId(null);
    setAnchorEl(null);
    navigate(`/dashboard/petlist/edit/${id}?from=petlist`);
  };
  const handleDeletePet = () => {
    var petid = currentPetId;
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/${petid}?storeId=${appContext.storeId}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          var tempRows = rows.filter((row) => row.id !== petid);
          setRows(tempRows);
          var tempunfilteredRows = unfilteredRows.filter((row) => row.id !== petid);
          setUnfilteredRows(tempunfilteredRows);
        })
        .catch((error) => {
          console.error("Delete pet error", error);
        });
    }
    setCurrentPetId(null);
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCreatePet = () => {
    navigate(`/dashboard/petlist/create`);
  };
  const handleSearch = (event) => {
    var tempRows = unfilteredRows.filter(
      (row) => row.name.toLowerCase().includes(event.target.value.toLowerCase()) || row.chipNumber.includes(event.target.value)
    );
    setRows(tempRows);
    setPage(0);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>寵物清單 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle}>
            <Link underline="none">
              <FormatListBulletedIcon sx={{ mr: 1 }} />
              寵物清單
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap " }}>
            <Button variant="outlined" onClick={handleCreatePet} startIcon={<AddCircleIcon />}>
              新增
            </Button>
            <Button variant="outlined" startIcon={<FilterAltIcon />} sx={{ display: "none" }}>
              篩選
            </Button>
            <Box id="search-bar">
              <TextField
                id="search-input-field"
                label="名稱或晶片號碼"
                variant="outlined"
                InputLabelProps={{ size: "small" }}
                color="primary"
                onChange={handleSearch}
                sx={{
                  width: "250px",
                  height: "40px",
                  fontSize: "12px",
                  "& label.MuiInputLabel-root": {
                    color: "#aaa",
                  },
                  "& label.Mui-focused": {
                    color: "var(--color-primary)",
                  },
                  "& .MuiInputBase-input": { padding: 1, color: "var(--color-on-surface)" },
                  "& .MuiOutlinedInput-root": {
                    color: "var(--color-on-surface)",
                    "& fieldset": {
                      borderColor: "#aaa",
                    },
                    "&:hover fieldset": {
                      borderColor: "var(--color-on-surface)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--color-primary)",
                    },
                  },
                }}
              />
              <IconButton aria-label="search" disabled={true} sx={{ "&:hover": { bgcolor: "var(--color-surface)" }, borderRadius: "8px" }}>
                <SearchIcon fontSize="medium" sx={{ color: "var(--color-on-surface)" }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          {loading ? (
            <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={118} />
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "var(--color-surface)",
                borderRadius: "8px",
                // minWidth: "300px",
                margin: "0px auto",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "var(--color-surface)",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Menu
                  id="pet-item-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "& .MuiMenu-paper": { backgroundColor: "var(--color-surface-variant)", color: "var(--color-on-surface)" },
                    "& .MuiListItemIcon-root": { color: "var(--color-on-surface)" },
                  }}
                >
                  <MenuItem onClick={handleViewPet}>
                    <ListItemIcon>
                      <ManageSearchIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>檢視</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleEditPet}>
                    <ListItemIcon>
                      <EditIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>編輯</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowDeleteAlert(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>刪除</ListItemText>
                  </MenuItem>
                </Menu>
                <DeleteAlert
                  showDeleteAlert={showDeleteAlert}
                  handleDelete={handleDeletePet}
                  handleClose={() => {
                    handleMenuClose();
                    setShowDeleteAlert(false);
                  }}
                />
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" sx={{ ...tableCellStyle, maxWidth: "30px" }}></TableCell>
                      <TableCell sx={{ ...tableCellStyle, minWidth: "100px" }}>名字</TableCell>
                      <TableCell sx={tableCellStyle}>晶片號碼</TableCell>
                      <TableCell sx={tableCellStyle}>性別</TableCell>
                      <TableCell sx={tableCellStyle}>年齡</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell sx={{ ...tableCellStyle, width: "30px", pr: { xs: 0 } }}>
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={(e) => handleMenuClick(e, row.id)}
                            sx={{ mr: 0 }}
                          >
                            <MoreHorizIcon sx={{ width: "24px", height: "24px" }} />
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ ...tableCellStyle, minWidth: "100px" }}> {row.profile} </TableCell>
                        <TableCell sx={{ ...tableCellStyle, fontFamily: "monospace" }}>{row.chipNumber}</TableCell>
                        <TableCell sx={tableCellStyle}>{row.gender}</TableCell>
                        <TableCell sx={tableCellStyle}>{row.age}</TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 73 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={5} sx={{ borderBottom: "1px solid var(--color-primary)" }} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="每頁顯示"
                labelDisplayedRows={({ from, to, count }) => `${from} - ${to} / ${count}`}
                sx={tablePaginationStyle}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default PetList;
