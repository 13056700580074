import "./Main.scss";
import { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Paper, Modal, Link, TextField, Alert } from "@mui/material";
import { FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import alldevice from "./alldevice.webp";
import petlist from "./petlist.webp";
import couplelist from "./couplelist.webp";
import duedate from "./duedate.webp";
import genealogy from "./genealogy.webp";
import tradelist from "./tradelist.webp";
import Footer from "./Footer";
import AppBar from "./AppBar";
const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const CustomLink = styled(Link)(({ theme }) => ({
  color: "#509BE1",
  textDecoration: "none",
}));
const CustomTextField = styled(TextField)(({ theme }) => ({
  // minWidth: "280px",
  // maxWidth: "400px",
  // height: "",
  "& label.MuiInputLabel-root": {
    color: "#0B0F19",
  },
  "& label.Mui-focused": {
    color: "var(--color-primary)",
  },
  "& .MuiInputBase-input": { padding: "0px 1rem", color: "#0B0F19", height: "56px" },
  "& .MuiOutlinedInput-root": {
    color: "#0B0F19",
    "& fieldset": {
      borderColor: "#0B0F19",
    },
    "&:hover fieldset": {
      borderColor: "#0B0F19",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-primary)",
    },
    "& textarea": {
      padding: "0px",
    },
  },
}));
function UncontrolledTextField(prop) {
  return (
    <Controller
      {...prop}
      render={({ field }) => (
        <CustomTextField
          {...field}
          type={prop.type}
          InputLabelProps={{ size: "normal" }}
          sx={{ ...prop.sx }}
          label={prop.label}
          variant="outlined"
          autoComplete="email"
          color="primary"
        />
      )}
    />
  );
}
function Divider() {
  return (
    <Box
      sx={{
        background: "linear-gradient(90deg, rgba(239, 108, 0, 0) 0%, #EF6C00 21.52%, #EF6C00 77.08%, rgba(239, 108, 0, 0) 100%)",
        height: "1px",
        width: { xs: "100%", sm: "75%" },
        m: "auto",
      }}
    />
  );
}
function Description({ title, description, src, alt }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: { xs: "40px 20px", sm: "80px 20px" }, gap: 2 }}>
      <Typography sx={{ fontSize: "36px", width: { xs: "100%", sm: "75%" }, alignSelf: "center" }}>{title}</Typography>
      <Typography sx={{ fontSize: "20px", width: { xs: "100%", sm: "75%" }, alignSelf: "center" }}>{description}</Typography>
      <Box component="img" src={src} sx={{ width: { xs: "100%", sm: "75%" }, alignSelf: "center" }} alt={alt} />
    </Box>
  );
}
function Main() {
  const [openSignUp, setOpenSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signUpResult, setSignUpResult] = useState({ value: false, severity: "", msg: "" });
  const [validating, setValidating] = useState(false);
  // const [resendVerification, setResendVerification] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  useEffect(() => {
    document.querySelector("body").style.background = "#ffffff";
  }, []);
  const onSubmit = (data) => {
    // console.debug(data);
    if (data.email === "") {
      setError("email", { type: "format", message: "email不能為空" });
      return;
    }
    if (data.password === "") {
      setError("password", { type: "required", message: "密碼不能為空" });
      return;
    }
    setValidating(true);
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        setSignUpResult({ value: false, msg: "" });
        // Signed in
        // console.log("userCredential", userCredential);
        const user = userCredential.user;
        var actionCodeSettings = {
          url: `${process.env.REACT_APP_FRONTEND_URL}/login?demo=1`,
        };
        sendEmailVerification(user, actionCodeSettings)
          .then(() => {
            // Email verification sent!
            // console.log("Email verification sent");
            setSignUpResult({ value: true, severity: "success", msg: "已寄送註冊確認信到您的信箱，請到信箱確認" });
            setValidating(false);
          })
          .catch((error) => {
            console.log(error);
            setSignUpResult({ value: true, severity: "error", msg: error.message });
            setValidating(false);
          });
        signOut(auth);
      })
      .catch((error) => {
        // console.log(error.code, error.message);
        setValidating(false);
        switch (error.code) {
          case "auth/email-already-in-use":
            setSignUpResult({ value: true, severity: "error", msg: "該Email已被註冊" });
            // setResendVerification(true);
            break;
          default:
            setSignUpResult({ value: true, severity: "error", msg: error.message });
            break;
        }
      });
  };
  return (
    <Box sx={{ p: 0, maxWidth: "1200px", margin: "auto" }}>
      <Helmet>
        <title>寵理才</title>
      </Helmet>
      <Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={12}>
          <AppBar />
          <Box
            sx={{
              background: "linear-gradient(162.39deg, rgba(18, 30, 61, 0.85) 0%, #0B0F19 100%)",
              // height: "260px",
              display: "flex",
              flexDirection: "column",
              //   justifyContent: "center",
              alignItems: "center",
              padding: "50px 32px",
              gap: "35px",
              boxSizing: "border-box",
            }}
          >
            <Typography sx={{ color: "white", fontSize: "2.5rem" }}>更輕鬆的管理您的寵物</Typography>
            <Typography sx={{ color: "white", fontSize: "1.5rem" }}>清楚掌握寵物的繁殖關係及銷售情況</Typography>
            <Button variant="contained" sx={{ fontSize: "16px" }} href="#join-member">
              加入會員
            </Button>
          </Box>
          <Description title="適用於各種裝置" description="在所有場合掌握您的商店" src={alldevice} alt={"all device"} />
          <Divider />
          <Description title="寵物清單" description="管理所有寵物的詳細資訊" src={petlist} alt={"pet list"} />
          <Divider />
          <Description title="重要日期提醒" description="以日曆顯示待產日及疫苗施打日期" src={duedate} alt={"due date"} />
          <Divider />
          <Description title="配種清單" description="記錄所有曾經配種過的寵物，並能簡單的新增後代" src={couplelist} alt={"couple list"} />
          <Divider />
          <Description title="族譜樹" description="顯示每隻寵物的上一代及下一代，寵物之間的關係一目了然" src={genealogy} alt={"genealogy"} />
          <Divider />
          <Description title="買賣紀錄管理" description="記錄每一筆交易，一鍵下載買賣契約" src={tradelist} alt={"trade list"} />
          <Divider />
          <Box id="join-member" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "160px", p: 2 }}>
            <Typography sx={{ fontSize: "20px" }}>現在加入會員，開始使用寵理才寵物管理系統，協助您管理並追蹤每隻寵物狀態</Typography>
          </Box>
          <Paper
            elevation={10}
            sx={{
              boxSizing: "border-box",
              m: "auto",
              width: { xs: "90%", sm: "50%", md: "50%" },
              p: 2,
              background: "linear-gradient(162.39deg, rgba(18, 30, 61, 0.85) 0%, #0B0F19 100%)",
              maxWidth: "500px",
              minWidth: "280px",
            }}
          >
            <Box id="plan" sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center" }}>
              <Typography sx={{ fontSize: "20px" }}>會員方案</Typography>
              {/* <Typography sx={{ fontSize: "36px",color: "var(--color-primary)"}}>免費試用一個月</Typography> */}
              {/* <Typography sx={{ fontSize: "24px", textDecoration:"line-through" }}>NT$ 299 / 月</Typography> */}
              <Typography sx={{ fontSize: "36px"}}>NT$ 299 / 月</Typography>
              <Box id="plan-description" sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography sx={{ fontSize: "16px" }}>• 寵物清單 - 管理所有寵物的詳細資訊</Typography>
                <Typography sx={{ fontSize: "16px" }}>• 重要日期提醒 - 以日曆顯示待產日及疫苗施打日期</Typography>
                <Typography sx={{ fontSize: "16px" }}>• 配種清單 - 記錄所有曾經配種過的寵物，並能簡單的新增後代 </Typography>
                <Typography sx={{ fontSize: "16px" }}>• 族譜樹 - 顯示每隻寵物的上一代及下一代，寵物之間的關係一目了然 </Typography>
                <Typography sx={{ fontSize: "16px" }}>• 買賣紀錄管理 - 記錄每一筆交易，一鍵下載買賣契約</Typography>
              </Box>
              <Button
                variant="contained"
                sx={{ fontSize: "16px" }}
                onClick={() => {
                  setOpenSignUp(true);
                }}
              >
                加入會員
              </Button>
            </Box>
          </Paper>
          <Modal
            open={openSignUp}
            onClose={() => {
              setOpenSignUp(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "300px", sm: "400px" },
                bgcolor: "white",
                borderRadius: "8px",
                boxShadow: 24,
                p: 4,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <UncontrolledTextField
                name="email"
                label="Email"
                control={control}
                defaultValue=""
                rules={{ pattern: { value: emailRegex, message: "Email格式錯誤" } }}
                sx={{ width: "100%" }}
                type="email"
              />
              {errors.email?.message && <Box sx={{ color: "#B00020", mt: -2 }}>{errors.email.message}</Box>}
              <Controller
                name="password"
                label="密碼"
                control={control}
                defaultValue=""
                rules={{ minLength: { value: 6, message: "需為數字或大小寫英文字母且至少6個字元" } }}
                render={({ field }) => (
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      sx={{
                        color: "#0B0F19",
                        fontFamily: "var(--font-family)",
                        fontSize: "1rem",
                        // "&.Mui-focused": {
                        //   color: "var(--color-primary)",
                        //   fontSize: "16px",
                        // },
                      }}
                    >
                      密碼
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={field.value}
                      onChange={field.onChange}
                      sx={{
                        color: "#0B0F19",
                        fontFamily: "var(--font-family)",
                        fontSize: "1rem",
                        "& fieldset": { borderColor: "#0B0F19" },
                        "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0B0F19" },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "var(--color-primary)" },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            edge="end"
                            sx={{ color: "#0B0F19" }}
                            size="large"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="密碼"
                    />
                  </FormControl>
                )}
              />
              {errors.password?.message && <Box sx={{ color: "#B00020", mt: -2 }}>{errors.password.message}</Box>}
              {signUpResult.value && <Alert severity={signUpResult.severity}>{signUpResult.msg}</Alert>}
              <Typography id="modal-modal-title" sx={{ fontSize: "1rem" }}>
                已經是會員?
                <CustomLink href="/login">登入</CustomLink>
              </Typography>
              <Button
                variant="contained"
                sx={{ fontSize: "1rem", width: "100%" }}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
              >
                {validating ? <CircularProgress sx={{ color: "var(--color-on-primary)" }} size={30} /> : "加入會員"}
              </Button>
              <Typography id="modal-modal-description" sx={{ fontSize: "12px" }}>
                加入會員表示同意我們的
                <CustomLink href="/info/terms-of-service" target="_blank" rel="noopener noreferrer">
                  服務條款
                </CustomLink>
                和
                <CustomLink href="/info/privacy" target="_blank" rel="noopener noreferrer">
                  隱私權政策
                </CustomLink>
              </Typography>
            </Box>
          </Modal>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
}
export default Main;
