import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PetPhotoAvatar from "./PetPhotoAvatar";

const tableCellStyle = {
  fontSize: "16px",
  color: "var(--color-on-surface)",
  borderBottom: "1px solid var(--color-primary)",
  padding: 2,
};
function Profile(src, name, id) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        color: "var(--color-on-surface)",
      }}
    >
      <PetPhotoAvatar src={src} name={name} id={id}/>
      <Typography sx={{ fontSize: "16px" }}>{name}</Typography>
    </Box>
  );
}
const tablePaginationStyle = {
  height: "40px",
  "& .MuiTablePagination-spacer": {
    flex: "0 1 auto",
  },
  "& .MuiTablePagination-toolbar": {
    height: "40px",
    minHeight: "40px",
    justifyContent: "flex-end",
  },
  "& .MuiTablePagination-selectLabel": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiInputBase-root": {
    marginRight: { xs: "16px", md: "32px" },
  },
  "& .MuiTablePagination-select": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    lineHeight: "32px",
    padding: "0px",
  },
  "& .MuiTablePagination-selectIcon": {
    color: "var(--color-on-surface)",
    height: "24px",
    width: "24px",
    top: "4px",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiTablePagination-actions": {
    color: "var(--color-on-surface)",
    ml: { xs: "16px", md: "32px" },
  },
  "& .MuiTablePagination-actions .MuiSvgIcon-root": {
    height: "24px",
    width: "24px",
  },
};
export default function VaccinationDateTable({vaccinationList}) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);

  function createData(id, name, date, vaccine) {
    return { id, name, date, vaccine };
  }
  useEffect(() => {
    var tempRows = [];
    for (const petObject of vaccinationList) {
      tempRows.push(createData(petObject.id, Profile(petObject.photoUrl, petObject.name, petObject.id), petObject.date ,petObject.vaccine));
    }
    setRows(tempRows);
  }, [vaccinationList]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: "var(--color-surface)",
        backgroundImage: "none",
        borderRadius: "8px",
        maxWidth: "500px",
        minWidth: "300px",
        margin: "0px auto",
      }}
    >
      <Table sx={{}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={tableCellStyle}>名字</TableCell>
            <TableCell sx={tableCellStyle}>接種日</TableCell>
            <TableCell sx={tableCellStyle}>疫苗種類</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
            <TableRow key={row.id+i}>
              <TableCell scope="row" sx={tableCellStyle}>
                {row.name}
              </TableCell>
              <TableCell sx={tableCellStyle}>{row.date}</TableCell>
              <TableCell sx={tableCellStyle}>{row.vaccine}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 73 * emptyRows,
              }}
            >
              <TableCell colSpan={3} sx={{ borderBottom: "1px solid var(--color-primary)" }} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="每頁顯示"
        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} / ${count}`}
        sx={tablePaginationStyle}
      />
    </TableContainer>
  );
}
