import {Box, Button, Link, Typography} from "@mui/material";
export default function AppBar() {
  return (
    <Box id="app-bar" sx={{ display: "flex", height: "40px", p: "0px 20px" }}>
      <Link href="/" underline="none">
        <Typography id="app-title" sx={{ color: "var(--color-primary)", fontSize: "20px", lineHeight: "40px" }}>
          寵理才
        </Typography>
      </Link>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: "flex" }}>
        <Button sx={{ color: "#0B0F19", fontSize: "16px" }} href="#join-member">
          加入會員
        </Button>
        <Button sx={{ color: "#0B0F19", fontSize: "16px" }} href="dashboard">
          登入
        </Button>
      </Box>
    </Box>
  );
}
