import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import ImageList from "@mui/material/ImageList";
import Skeleton from "@mui/material/Skeleton";
import ImageListItem from "@mui/material/ImageListItem";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Divider from "@mui/material/Divider";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import PetPhotoAvatar from "../component/PetPhotoAvatar";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
// const tempData = {
//   this: {
//     id: "3",
//     name: "種母",
//     gender: "母",
//     chipNumber: "11111111111110",
//     photoUrl: "string",
//   },
//   ancestor: {
//     gen1: [
//       {
//         id: "1",
//         name: "祖父",
//         gender: "公",
//         chipNumber: "11111111111112",
//         photoUrl: "string",
//       },
//       {
//         id: "2",
//         name: "祖母",
//         gender: "母",
//         chipNumber: "11111111111113",
//         photoUrl: "string",
//       },
//     ],
//   },
//   couples: [
//     {
//       couple: {
//         id: "4",
//         name: "配偶",
//         gender: "公",
//         chipNumber: "11111111111114",
//         photoUrl: "string",
//       },
//       descendant: [
//         {
//           id: "5",
//           name: "子嗣",
//           gender: "母",
//           chipNumber: "11111111111115",
//           photoUrl: "string",
//         },
//       ],
//     },
//   ],
// };

// const MyImageList = styled(ImageList)(({ theme }) => ({
// [theme.breakpoints.down("md")]: {
//   gridTemplateColumns: "repeat(1,1fr) !important",
// },
// [theme.breakpoints.up("md")]: {
//   gridTemplateColumns: "repeat(2,1fr) !important",
// },
// [theme.breakpoints.up("lg")]: {
//   gridTemplateColumns: "repeat(3,1fr) !important",
// },
// }));

const detailStyle = {
  display: "flex",
  flexDirection: "column",
  // gap: "8px",
  width: "100%",
  maxWidth: "120px",
  color: "var(--color-on-surface)",
  textAlign: "center",
};

function Description(prop) {
  return (
    <Box sx={detailStyle} aria-label="pet info">
      <Box>{prop.info.name}</Box>
      <Box>{prop.info.gender}</Box>
      <Box sx={{ fontSize: "14px" }}>{prop.info.chipNumber}</Box>
    </Box>
  );
}

function RowDisplay({ pet, avatarSx }) {
  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="center">
      {pet !== null ? (
        <>
          <PetPhotoAvatar src={pet.photoUrl} name={pet.name} id={pet.id} sx={avatarSx}></PetPhotoAvatar>
          <Description info={pet} />
        </>
      ) : null}
    </Grid>
  );
}

function ColumnDisplay({ pet }) {
  return (
    <Grid item container direction="column" alignItems="center" justifyContent="center" sx={{ width: "120px" }}>
      {pet !== null ? (
        <>
          <PetPhotoAvatar src={pet.photoUrl} name={pet.name} id={pet.id} sx={{width:"48px",height:"48px"}}></PetPhotoAvatar>
          <Description info={pet} />
        </>
      ) : null}
    </Grid>
  );
}

function ParentDisplay({ ancestor }) {
  if (Object.keys(ancestor).length !== 0 && ancestor.gen1 !== null) {
    return (
      <Grid
        container
        padding={2}
        direction="row"
        wrap="nowrap"
        sx={{ width: "440px", borderRadius: "8px" }}
        alignContent="center"
        justifyContent="center"
        backgroundColor="var(--color-surface)"
      >
        {ancestor.gen1.map((pet) => (
          <ColumnDisplay key={pet.id} pet={pet} />
        ))}
      </Grid>
    );
  } else {
    return null;
  }
}

function MainDisplay({ pet }) {
  return (
    <Grid container direction="row" wrap="nowrap" sx={{ width: "440px", height: "80px" }} alignContent="center" justifyContent="center">
      <RowDisplay pet={pet} avatarSx={{height:"60px",width:"60px"}} />
    </Grid>
  );
}

function ChildrenDescription({ family }) {
  return (
    <Grid container sx={{ width: "260px", borderRadius: "8px" }} backgroundColor="#303044">
      <Grid item container justifyContent="center" m={1}>
        <RowDisplay pet={family.couple} avatarSx={{height:"48px",width:"48px"}} />
      </Grid>
      <Divider variant="middle" sx={{ borderColor: "var(--color-on-surface)", width: "90%" }} />
      <Grid rowSpacing="16px" item container direction="row" justifyContent="space-around" m="0 8px 8px 8px">
        {family.descendant.map((pet) => (
          <ColumnDisplay key={pet.id} pet={pet} />
        ))}
      </Grid>
    </Grid>
  );
}

function ChildrenDisplay(prop) {
  var columns = 0;
  if (prop.uplgMatches) {
    if (prop.couples.length < 3) {
      columns = prop.couples.length;
    } else {
      columns = 3;
    }
  } else if (prop.upmdMatches) {
    if (prop.couples.length < 2) {
      columns = prop.couples.length;
    } else {
      columns = 2;
    }
  } else {
    columns = 1;
  }
  // console.debug("columns: ", columns);
  return (
    <ImageList gap={12} sx={{ overflowY: "hidden", gridTemplateColumns: `repeat(${columns},1fr) !important` }}>
      {prop.couples.map((couple) => (
        <ImageListItem key={couple.couple.id} sx={{ lineHeight: "normal" }}>
          <ChildrenDescription key={couple.couple.id} family={couple} />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

function Genealogy() {
  const [loading, setLoading] = useState(true);
  const [genealogy, setGenealogy] = useState({});
  const appContext = useContext(AppContext);
  let params = useParams();
  const theme = useTheme();
  const downmdMatches = useMediaQuery(theme.breakpoints.down("md"));
  const upmdMatches = useMediaQuery(theme.breakpoints.up("md"));
  const uplgMatches = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/genealogy/${params.petid}?storeId=${appContext.storeId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken /* 把token放在這 */,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        }) /*把response json化*/
        .then((response) => {
          // console.debug(response);
          setGenealogy(response);
          setLoading(false);
          /*接到request data後要做的事情*/
        })
        .catch((e) => {
          console.error("fail");
          /*發生錯誤時要做的事情*/
        });
    }
  }, []);

  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>寵物族譜 - 寵理才</title>
      </Helmet>
      <Grid item xs={4} sm={8} md={12}>
        <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon />}>
          <Link underline="hover" href="/dashboard/petlist">
            <FormatListBulletedIcon sx={{mr: 1}}/>
            寵物清單
          </Link>
          <Typography>檢視</Typography>
          <Typography>族譜</Typography>
        </Breadcrumbs>
      </Grid>
      {loading ? (
        <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={118} />
        </Box>
      ) : (
        <Grid container rowSpacing={2} direction="column">
          <Grid item container justifyContent="center" alignContent="center">
            {Object.keys(genealogy).length !== 0 ? <ParentDisplay ancestor={genealogy.ancestor} /> : null}
          </Grid>
          <Grid item container justifyContent="center" alignContent="center">
            {Object.keys(genealogy).length !== 0 ? <MainDisplay pet={genealogy.this} /> : null}
          </Grid>
          <Grid item container justifyContent="center" alignContent="center">
            {Object.keys(genealogy).length !== 0 && genealogy.couples !== null ? (
              <ChildrenDisplay downmdMatches={downmdMatches} upmdMatches={upmdMatches} uplgMatches={uplgMatches} couples={genealogy.couples} />
            ) : null}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default Genealogy;
