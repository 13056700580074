import { useState, useEffect, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import { AppContext } from "../AppContext";
import catIcon from "../dashboard/cat-icon.png";

function PetPhotoAvatar({ src, name, id, sx }) {
  const [photoUrl, setPhotoUrl] = useState(catIcon);
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (src !== "" && appContext.idToken !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/photo/${src}`, {
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
        })
        .then(function (myBlob) {
          setPhotoUrl(URL.createObjectURL(myBlob));
        })
        .catch(function (error) {
          console.error("fetch pet photo error: ", error.message);
          return catIcon;
        });
    }
  },[]);

  return (
    <Avatar
      component="a"
      href={`/dashboard/petlist/${id}`}
      alt={name}
      src={photoUrl}
      sx={sx}
    />
  );
}

export default PetPhotoAvatar;
