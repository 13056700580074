import { useState } from "react";
import { FormControl, FormHelperText, OutlinedInput, IconButton, InputLabel, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PasswordTextfield(props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl sx={{ position: "relative", mt: 2, width: "100%" }} variant="outlined">
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{
          fontSize: "16px",
          "&.Mui-focused": {
            color: "var(--color-primary)",
            fontSize: "16px",
          },
        }}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={props.field.value}
        onChange={props.field.onChange}
        sx={{
          color: "var(--color-on-surface)",
          fontFamily: "var(--font-family)",
          fontSize: "16px",
          "& fieldset": { borderColor: "#888888" },
          "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "var(--color-on-surface)" },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "var(--color-primary)" },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              edge="end"
              sx={{ color: "var(--color-on-surface)" }}
              size="large"
            >
              {showPassword ? <VisibilityOff sx={{ fontSize: "24px" }} /> : <Visibility sx={{ fontSize: "24px" }} />}
            </IconButton>
          </InputAdornment>
        }
        label={props.label}
      />
      {/* <FormHelperText sx={{ color: "#f44336" }}>
        {passWordValues.password !== "" && !passWordValues.verified ? "需大小寫英文字母且至少6個字元" : ""}
      </FormHelperText> */}
    </FormControl>
  );
}

export default PasswordTextfield;
