import { useEffect, useState } from "react";
import { Container, Paper, TextField, Typography, Button, Box, Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Helmet } from "react-helmet";
const CustomTextField = styled(TextField)(({ theme }) => ({
  //   minWidth: "250px",
  height: "56px",
  "& label.MuiInputLabel-root": {
    color: "#aaa",
  },
  "& label.Mui-focused": {
    color: "var(--color-primary)",
  },
  "& .MuiInputBase-input": { padding: "0px 16px", color: "var(--color-on-surface)", height: "56px" },
  "& .MuiOutlinedInput-root": {
    color: "var(--color-on-surface)",
    "& fieldset": {
      borderColor: "#aaa",
    },
    "&:hover fieldset": {
      borderColor: "var(--color-on-surface)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-primary)",
    },
    "& textarea": {
      padding: "0px",
    },
  },
}));
function UncontrolledTextField(prop) {
  return (
    <Controller
      name={prop.name}
      control={prop.control}
      defaultValue={prop.defaultValue}
      rules={prop.rules}
      render={({ field }) => (
        <CustomTextField
          {...field}
          multiline={prop.multiline}
          sx={{ ...prop.sx }}
          label={prop.label}
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{ size: "normal" }}
          color="primary"
        />
      )}
    />
  );
}

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
function ForgetPassword() {
  const [sent, setSent] = useState(false);
  const [result, setResult] = useState({ value: false, severity: "", msg: "" });
  useEffect(() => {
    document.querySelector("body").style.background = "var(--color-background)";
  }, []);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const onSubmit = (data) => {
    console.debug(data);
    if (data.email === "") {
      setError("email", { type: "format", message: "Email不能為空" });
      return;
    }
    const auth = getAuth();
    var actionCodeSettings = {
      url: `${process.env.REACT_APP_FRONTEND_URL}/login`,
      handleCodeInApp: true
    };
    sendPasswordResetEmail(auth, data.email, actionCodeSettings)
      .then(() => {
        console.log("Password reset email sent!");
        setSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.debug(errorCode, errorMessage);
        switch (errorCode) {
          case "auth/user-not-found":
            setResult({ value: true, severity: "error", msg: "使用者不存在" });
            break;
          case "auth/invalid-email":
            setResult({ value: true, severity: "error", msg: "Email格式錯誤" });
            break;
          default:
            setResult({ value: true, severity: "error", msg: error.message });
            break;
        }
      });
  };
  return (
    <Container sx={{ height: "100vh" }}>
      <Helmet>
        <title>忘記密碼 - 寵理才</title>
      </Helmet>
      <Paper
        sx={{
          position: "relative",
          top: "50%",
          background: "var(--color-surface)",
          width: "300px",
          m: "auto",
          transform: "translate(0%, -50%)",
          p: 4,
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", alignItems: "center" }}>
          <Typography sx={{ alignSelf: "start" }}>忘記密碼</Typography>
          {sent ? (
            <>
              <Typography sx={{ alignSelf: "start" }}>重設密碼信件已寄送到您的信箱，請前往查收</Typography>
              <Button variant="contained" sx={{ fontSize: "16px", width: "100%" }} href="/login">
                返回登入頁面
              </Button>
            </>
          ) : (
            <>
              <UncontrolledTextField
                name="email"
                label="Email"
                control={control}
                defaultValue=""
                rules={{ pattern: { value: emailRegex, message: "Email格式錯誤" } }}
                sx={{ width: "100%" }}
                type="email"
              />
              {errors.email?.message && <Box sx={{ color: "#B00020", mt: -3 }}>{errors.email.message}</Box>}
              {result.value && <Alert severity={result.severity}>{result.msg}</Alert>}
              <Button
                variant="contained"
                sx={{ fontSize: "16px", width: "100%" }}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
              >
                取得重設密碼連結
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default ForgetPassword;
