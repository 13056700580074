import { Box, Typography, Link } from "@mui/material";
function Divider() {
  return (
    <Box
      sx={{
        background: "linear-gradient(90deg, rgba(239, 108, 0, 0) 0%, #EF6C00 21.52%, #EF6C00 77.08%, rgba(239, 108, 0, 0) 100%)",
        height: "1px",
        width: { xs: "100%", sm: "75%" },
        m: "auto",
      }}
    />
  );
}
function Footer() {
  return (
    <Box
      id="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#00453D",
        gap: 1,
        mt: "4rem",
        p: 1,
      }}
    >
      <Box id="info" sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 1 }}>
        <Link href="/info/terms-of-service" sx={{ color: "white" }}>
          服務條款
        </Link>
        <Link href="/info/privacy" sx={{ color: "white" }}>
          隱私權政策
        </Link>
        <Link href="/info/return-policy" sx={{ color: "white" }}>退換貨說明</Link>
        <Typography sx={{ color: "white" }}>客服信箱：support@budylon.com</Typography>
      </Box>
      <Divider />
      <Box id="copy-right" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography sx={{ fontFamily: "serif", fontSize: "16px", color: "white" }}>
          © 2022{" "}
          <Box component="span" color="#A7CF57">
            Budylon
          </Box>
          , All Right Reversed.
        </Typography>
      </Box>
    </Box>
  );
}
export default Footer;