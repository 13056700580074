import "./LoginPage.css";
import { useState, useContext, useEffect } from "react";
import { Box, Alert, Link, FormControl, CircularProgress, TextField, Button, Container } from "@mui/material";
import { FormHelperText, OutlinedInput, IconButton, InputLabel, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AppContext } from "./AppContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, reload } from "firebase/auth";
import { Helmet } from "react-helmet";

function LoginPage() {
  const [emailValues, setEmailValues] = useState({
    verified: false,
    email: "",
  });
  const [passWordValues, setPassWordValues] = useState({
    password: "",
    verified: false,
    showPassword: false,
  });
  const [signUpResult, setSignUpResult] = useState({ value: false, severity: "", msg: "" });
  const [validating, setValidating] = useState(false);
  const [useLoginButton, setUseLoginButton] = useState(false);
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const appContext = useContext(AppContext);
  const auth = getAuth(appContext.app);
  useEffect(() => {
    document.querySelector("body").style.background = "var(--color-background)";
    onAuthStateChanged(auth, (user) => {
      if (useLoginButton) {
        return;
      }
      if (user) {
        verifyUser(user);
      }
    });
  }, []);

  const handlePasswordChange = (event) => {
    if (event.target.value.length < 6 || event.target.value === "") {
      setPassWordValues({ ...passWordValues, password: event.target.value, verified: false });
    } else {
      setPassWordValues({ ...passWordValues, password: event.target.value, verified: true });
    }
  };

  const handleClickShowPassword = () => {
    setPassWordValues({
      ...passWordValues,
      showPassword: !passWordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleVerifyEmail = (event) => {
    const regex = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    if (regex.test(event.target.value) === false || event.target.value === "") {
      setEmailValues({ ...emailValues, verified: false, email: event.target.value });
    } else {
      setEmailValues({ ...emailValues, verified: true, email: event.target.value });
    }
  };
  const resendVerificationEmail = () => {
    const auth = getAuth();
    var actionCodeSettings = {
      url: `${process.env.REACT_APP_FRONTEND_URL}/login`,
    };
    sendEmailVerification(auth.currentUser, actionCodeSettings)
      .then(() => {
        setSignUpResult({ value: true, severity: "success", msg: "已寄送註冊確認信到您的信箱，請到信箱確認" });
        setValidating(false);
      })
      .catch((error) => {
        console.log(error);
        setSignUpResult({ value: true, severity: "error", msg: error.message });
        setValidating(false);
      });
  };
  const verifyUser = (user) => {
    if (!user.emailVerified) {
      setSignUpResult({
        value: true,
        severity: "error",
        msg: (
          <>
            尚未驗證Email
            <Link sx={{ fontFamily: "var(--font-family)", color: "#509BE1", ml: 1 }} href="javascript:void(0);" onClick={resendVerificationEmail}>
              重新寄送確認信
            </Link>
          </>
        ),
      });
      setValidating(false);
      return;
    } else {
      user.getIdTokenResult(true).then((token) => {
        if ("demo" in token.claims && token.claims.demo) {
        } else {
          if ("paid" in token.claims && token.claims.paid) {
          } else {
            setValidating(false);
            if (searchParams.has("demo")) {
              navigate(`/payment?demo=${searchParams.get("demo")}`);
            } else {
              navigate(`/payment`);
            }
            return;
          }
        }

        if ("role" in token.claims && token.claims.role.includes("admin")) {
          appContext.signedIn = true;
        } else {
          setValidating(false);
          setSignUpResult({ value: true, severity: "error", msg: "使用者權限不足，請聯繫本公司客服為您服務" });
          return;
        }
        if ("storeId" in token.claims && token.claims.storeId !== "") {
          appContext.storeId = token.claims.storeId;
        } else {
          setValidating(false);
          setSignUpResult({ value: true, severity: "error", msg: "使用者無所屬商店，請聯繫本公司客服為您服務" });
          return;
        }
        navigate("/dashboard/home");
      });
    }
  };

  const handleLogin = () => {
    setUseLoginButton(true);
    setValidating(true);
    if (emailValues.verified && emailValues.email !== "" && passWordValues.verified && passWordValues.password !== "") {
      signInWithEmailAndPassword(auth, emailValues.email, passWordValues.password)
        .then((userCredential) => {
          const user = userCredential.user;
          // console.debug(user);
          verifyUser(user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error("login fail", errorCode, errorMessage);
          setValidating(false);
          setSignUpResult({ value: true, severity: "error", msg: errorMessage });
          switch (errorCode) {
            case "auth/user-not-found":
              setSignUpResult({ value: true, severity: "error", msg: "使用者不存在" });
              setSignUpResult({
                value: true,
                severity: "error",
                msg: (
                  <>
                    使用者不存在
                    <Link sx={{ fontFamily: "var(--font-family)", color: "#509BE1", ml: 1 }} href="/">
                      前往註冊
                    </Link>
                  </>
                ),
              });
              break;
            case "auth/wrong-password":
              setSignUpResult({ value: true, severity: "error", msg: "密碼錯誤" });
              break;
            case "auth/invalid-email":
              setSignUpResult({ value: true, severity: "error", msg: "Email格式錯誤" });
              break;
            case "auth/network-request-failed":
              setSignUpResult({ value: true, severity: "error", msg: "網路連線異常，請檢查網路連線" });
              break;
            default:
              setSignUpResult({ value: true, severity: "error", msg: errorMessage });
              break;
          }
        });
    } else if (emailValues.email === "" && passWordValues.password === "") {
      setValidating(false);
      setSignUpResult({ value: true, severity: "error", msg: "請輸入帳號密碼" });
    } else {
      setValidating(false);
      setSignUpResult({ value: true, severity: "error", msg: "請輸入正確的帳號密碼" });
    }
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>登入 - 寵理才</title>
      </Helmet>
      <div className="login-image" />
      <Container className="right-panel">
        <Box className="right-panel-content">
          <div id="login-title">寵理才寵物管理系統</div>
          <div className="login-info">
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              required={true}
              type="email"
              error={!emailValues.verified && emailValues.email !== ""}
              value={emailValues.email}
              sx={{
                width: "100%",
                "& label.MuiInputLabel-root": {
                  color: "var(--color-on-surface)",
                  fontSize: "16px",
                },
                "& label.Mui-focused": {
                  color: "var(--color-primary)",
                  fontSize: "16px",
                },
                "& .MuiOutlinedInput-root": {
                  color: "var(--color-on-surface)",
                  fontSize: "16px",
                  "& fieldset": {
                    borderColor: "#888888",
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--color-on-surface)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--color-primary)",
                  },
                },
              }}
              onChange={handleVerifyEmail}
              helperText={emailValues.email !== "" && !emailValues.verified ? "Email格式錯誤" : ""}
            />
            <FormControl sx={{ position: "relative", mt: 2, width: "100%" }} variant="outlined">
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{
                  color: "var(--color-on-surface)",
                  fontFamily: "var(--font-family)",
                  fontSize: "16px",
                  "&.Mui-focused": {
                    color: "var(--color-primary)",
                    fontSize: "16px",
                  },
                }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={passWordValues.showPassword ? "text" : "password"}
                value={passWordValues.password}
                onChange={handlePasswordChange}
                sx={{
                  color: "var(--color-on-surface)",
                  fontFamily: "var(--font-family)",
                  fontSize: "16px",
                  "& fieldset": { borderColor: "#888888" },
                  "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "var(--color-on-surface)" },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "var(--color-primary)" },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: "var(--color-on-surface)" }}
                      size="large"
                    >
                      {passWordValues.showPassword ? <VisibilityOff sx={{ fontSize: "24px" }} /> : <Visibility sx={{ fontSize: "24px" }} />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText sx={{ color: "#f44336" }}>
                {passWordValues.password !== "" && !passWordValues.verified ? "需大小寫英文字母且至少6個字元" : ""}
              </FormHelperText>
            </FormControl>
            <Button
              variant="standard"
              sx={{
                position: "relative",
                right: "0",
                fontSize: "12px",
                fontFamily: "var(--font-family)",
                color: "var(--color-on-surface)",
                p: "0px",
              }}
              href="/login/forgetpassword"
            >
              忘記密碼？
            </Button>
          </div>

          <Button
            size="small"
            variant="contained"
            className="login-buttom"
            sx={{
              fontSize: "16px",
              backgroundColor: "var(--color-primary)",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "var(--color-primary-variant)",
              },
            }}
            onClick={handleLogin}
          >
            {validating ? <CircularProgress sx={{ color: "var(--color-on-primary)" }} size={30} /> : "登入"}
          </Button>
          {/* {loginResult.fail && (
            <div style={{ position: "relative", top: "430px", left: "calc((100% - 280px) / 2)", color: "rgb(244, 67, 54)", fontSize: "16px" }}>
              {loginResult.message}
            </div>
          )} */}
          {signUpResult.value && (
            <Alert
              sx={{ position: "relative", top: "440px", left: "calc((100% - 280px) / 2)", width: "280px", boxSizing: "border-box" }}
              severity={signUpResult.severity}
            >
              {signUpResult.msg}
            </Alert>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default LoginPage;
