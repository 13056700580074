import "./Main.scss";
import { useEffect, useState, useContext } from "react";
import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppContext } from "../AppContext";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

function Payment() {
  const [postData, setPostData] = useState("");
  const [isDemo, setIsDemo] = useState(false);
  const appContext = useContext(AppContext);
  const auth = getAuth(appContext.app);
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const verifyUser = (user) => {
    if (!user.emailVerified) {
      navigate("/login");
      return;
    } else {
      user.getIdTokenResult(true).then((token) => {
        if ("paid" in token.claims && token.claims.paid) {
          navigate("/login");
          return;
        }
      });
    }
  };
  useEffect(() => {
    document.querySelector("body").style.background = "#ffffff";
    if (searchParams.has("demo") && searchParams.get("demo") === "1") {
      setIsDemo(true);
    }
    onAuthStateChanged(auth, (user) => {
      // console.debug(user)
      if (user) {
        verifyUser(user);
        if (searchParams.has("demo") && searchParams.get("demo") === "1") {
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/api/v1/payment/postdata?payerEmail=${user.email}&periodAmt=299&periodTimes=99`)
            .then((response) => {
              if (response.ok) {
                return response.text();
              }
            })
            .then(function (postdata) {
              setPostData(postdata);
            })
            .catch(function (err) {
              console.log("Failed to fetch page: ", err);
            });
        }
      } else {
        navigate("/login");
      }
    });
  }, []);

  function handleDemo() {
    let user = auth.currentUser;
    if (user) {
      user.getIdToken().then((idToken) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/user/demo/${user.uid}`, {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer " + idToken,
          }),
        })
          .then((response) => {
            if (response.ok) {
              navigate("/login");
              return;
            }
          })
          .catch((error) => {
            console.error("Fetch demo request error", error);
          });
      });
    }
  }
  return (
    <Box sx={{ p: 0, maxWidth: "1200px", margin: "auto" }}>
      <Helmet>
        <title>付款 - 寵理才</title>
      </Helmet>
      <Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={12} sx={{ height: "100vh" }}>
          <Box id="app-bar" sx={{ display: "flex", height: "40px", p: "0px 20px" }}>
            <Typography id="app-title" sx={{ flexGrow: 1, color: "var(--color-primary)", fontSize: "20px", lineHeight: "40px" }}>
              寵理才
            </Typography>
            <Button
              sx={{ color: "#0B0F19", fontSize: "16px" }}
              href="dashboard"
              onClick={() => {
                signOut(auth)
                  .then(() => {
                    appContext.signedIn = false;
                    navigate("/login");
                  })
                  .catch((error) => {
                    console.error("Sign-out fail,", error);
                  });
              }}
            >
              登出
            </Button>
          </Box>
          <Paper
            elevation={10}
            sx={{
              boxSizing: "border-box",
              m: "auto",
              width: { xs: "90%", sm: "50%", md: "50%" },
              p: 2,
              background: "linear-gradient(162.39deg, rgba(18, 30, 61, 0.85) 0%, #0B0F19 100%)",
              maxWidth: "500px",
              minWidth: "280px",
            }}
          >
            <Box id="plan" sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center" }}>
              <Typography sx={{ fontSize: "20px" }}>會員方案</Typography>
              <Typography sx={{ fontSize: "36px" }}>NT$ 299 / 月</Typography>
              <Box id="plan-description" sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography sx={{ fontSize: "16px" }}>• 寵物清單 - 管理所有寵物的詳細資訊</Typography>
                <Typography sx={{ fontSize: "16px" }}>• 重要日期提醒 - 以日曆顯示待產日及疫苗施打日期</Typography>
                <Typography sx={{ fontSize: "16px" }}>• 配種清單 - 記錄所有曾經配種過的寵物，並能簡單的新增後代 </Typography>
                <Typography sx={{ fontSize: "16px" }}>• 族譜樹 - 顯示每隻寵物的上一代及下一代，寵物之間的關係一目了然 </Typography>
                <Typography sx={{ fontSize: "16px" }}>• 買賣紀錄管理 - 記錄每一筆交易，一鍵下載買賣契約</Typography>
              </Box>
              {isDemo ? (
                <Button sx={{ fontSize: "16px" }} variant="contained" onClick={handleDemo}>
                  申請試用
                </Button>
              ) : (
                <form action={process.env.REACT_APP_PAYMENT_API} method="POST">
                  <input type="hidden" name="MerchantID_" value="MS343019109" />
                  <input type="hidden" name="PostData_" value={postData} />
                  <Button type="submit" sx={{ fontSize: "16px" }} variant="contained">
                    確認付款
                  </Button>
                </form>
              )}
            </Box>
          </Paper>

          <Box
            id="footer"
            sx={{
              position: "absolute",
              bottom: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
              width: "100%",
              maxWidth: "1200px",
              background: "#00453D",
            }}
          >
            <Typography sx={{ fontFamily: "serif", fontSize: "16px", color: "white" }}>
              © 2022{" "}
              <Box component="span" color="#A7CF57">
                Budylon
              </Box>
              , All Right Reversed.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default Payment;
