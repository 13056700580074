import { useContext, useState, useEffect } from "react";
//mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";
import { AppContext } from "../AppContext";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useParams, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";
import DeleteAlert from "../component/DeleteAlert";
const CustomTextField = styled(TextField)(({ theme }) => ({
  minWidth: "250px",
  height: "56px",
  "& label.MuiInputLabel-root": {
    color: "#aaa",
  },
  "& label.Mui-focused": {
    color: "var(--color-primary)",
  },
  "& .MuiInputBase-input": { padding: "0px 16px", color: "var(--color-on-surface)", height: "56px" },
  "& .MuiOutlinedInput-root": {
    color: "var(--color-on-surface)",
    "& fieldset": {
      borderColor: "#aaa",
    },
    "&:hover fieldset": {
      borderColor: "var(--color-on-surface)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-primary)",
    },
  },
}));
function ControlledTextField(prop) {
  return (
    <Controller
      name={prop.name}
      control={prop.control}
      defaultValue={prop.defaultValue}
      rules={prop.rules}
      render={({ field }) => (
        <CustomTextField
          {...field}
          multiline={prop.multiline}
          sx={{ ...prop.sx }}
          label={prop.label}
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{ size: "normal" }}
          color="primary"
        />
      )}
    />
  );
}

function CoupleEdit() {
  const appContext = useContext(AppContext);
  const [showSaveAlert, setShowSaveAlert, redirectPath, setRedirectPath] = useOutletContext();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [displayDeleteBtn, setDisplayDeleteBtn] = useState("inherit");
  const [currentLocation, setCurrentLocation] = useState("");
  const [saveBtn, setSaveBtn] = useState("");
  const [couple, setCouple] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ state: false, msg: "" });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  let location = useLocation();
  let params = useParams();
  let navigate = useNavigate();
  let [querys] = useSearchParams();
  //watch input change
  useEffect(() => {
    const subscription = watch(() => (appContext.edited = true));
    return () => subscription.unsubscribe();
  }, [watch]);

  //fetch couple data
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      if (location.pathname.indexOf("/couplelist/edit") >= 0) {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/coupleList/${params.coupleid}?storeId=${appContext.storeId}`, {
          headers: new Headers({
            Authorization: "Bearer " + appContext.idToken,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            if (data !== null) {
              setCouple(data);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error("Fetch due date error", error);
          });
        setDisplayDeleteBtn("inherit");
        setCurrentLocation("編輯");
        setSaveBtn({
          method: "PUT",
          url: `${process.env.REACT_APP_API_URL}/api/v1/coupleList/${params.coupleid}?storeId=${appContext.storeId}`,
        });
      } else if (location.pathname.indexOf("/couplelist/create") >= 0) {
        setDisplayDeleteBtn("none");
        setCurrentLocation("新增");
        setLoading(false);
        setSaveBtn({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/v1/coupleList?storeId=${appContext.storeId}`,
        });
      }
    }
  }, [currentLocation, location.pathname]);

  //show error
  useEffect(() => {
    if (errors.fatherChipNumber) {
      // console.debug("fatherChipNumber error", errors.fatherChipNumber);
      setError({ state: true, msg: "父晶片號碼須為14碼數字" });
    } else if (errors.motherChipNumber) {
      // console.debug("motherChipNumber error", errors.motherChipNumber);
      setError({ state: true, msg: "母晶片號碼須為14碼數字" });
    } else {
      setError({ state: false, msg: "" });
    }
  }, [errors.fatherChipNumber, errors.motherChipNumber]);
  function SaveAlert({ showSaveAlert, handleClose }) {
    return (
      <Dialog open={showSaveAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">{"尚未儲存，是否儲存?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">尚未儲存，是否儲存?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleSubmit(onSubmit)();
              handleClose();
            }}
            variant="contained"
            autoFocus
          >
            儲存
          </Button>
          <Button
            onClick={() => {
              appContext.edited = false;
              handleClose();
              var _redirectPath = redirectPath;
              setRedirectPath("");
              navigate(_redirectPath);
            }}
          >
            直接離開
          </Button>
          <Button onClick={handleClose}>繼續編輯</Button>
        </DialogActions>
      </Dialog>
    );
  }
  const onSubmit = (data) => {
    // console.debug(data);
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(saveBtn.url, {
        method: saveBtn.method,
        body: JSON.stringify(data),
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          if (response.ok) {
            appContext.edited = false;
            if (redirectPath !== "") {
              var _redirectPath = redirectPath;
              setRedirectPath("");
              navigate(_redirectPath);
              return;
            }
            navigate(-1);
          } else if (response.status === 404) {
            response.json().then((data) => {
              setError({ state: true, msg: data.msg });
            });
          }
        })
        .catch((error) => {
          console.error("Post couple error", error);
        });
    }
  };
  const handleDeleteCouple = () => {
    var coupleid = params.coupleid;
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/coupleList/${coupleid}?storeId=${appContext.storeId}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            var from = querys.get("from");
            if (from === "coupleList") {
              navigate(-1, { replace: true });
            } else if (from === "coupleDetail") {
              navigate(-2, { replace: true });
            }
          }
        })
        .catch((error) => {
          console.error("Delete pet error", error);
        });
    }
  };
  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>配種編輯 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon fontSize="medium" />}>
            <Link
              underline="hover"
              href="#"
              onClick={() => {
                if (!appContext.edited) {
                  navigate("/dashboard/couplelist");
                } else {
                  setRedirectPath("/dashboard/couplelist");
                  setShowSaveAlert(true);
                }
              }}
            >
              <FeaturedPlayListIcon sx={{ mr: 1 }} />
              配種清單
            </Link>
            <Typography>{currentLocation}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
              <Button variant="outlined" onClick={handleSubmit(onSubmit)} startIcon={<SaveIcon />}>
                儲存
              </Button>
              <Button
                variant="outlined"
                component="label"
                sx={{ display: displayDeleteBtn }}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setShowDeleteAlert(true);
                }}
              >
                刪除
              </Button>
            </Box>
            <SaveAlert
              showSaveAlert={showSaveAlert}
              handleClose={() => {
                setShowSaveAlert(false);
              }}
            />
            <DeleteAlert
              showDeleteAlert={showDeleteAlert}
              handleDelete={handleDeleteCouple}
              handleClose={() => {
                setShowDeleteAlert(false);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Container
            sx={{
              bgcolor: "var(--color-surface)",
              width: { xs: 282, sm: 564 },
              padding: 2,
              borderRadius: "8px",
            }}
          >
            {error.state && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error.msg}
              </Alert>
            )}
            {loading ? (
              <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={210} height={118} />
              </Box>
            ) : (
              <form
                id="pet-edit-form"
                style={{
                  display: "flex",
                  gap: "16px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <ControlledTextField
                  name="fatherChipNumber"
                  label="父晶片號"
                  control={control}
                  defaultValue={!!couple.fatherChipNumber ? couple.fatherChipNumber : ""}
                  rules={{ pattern: /^\d{14}$/, required: true }}
                />
                <ControlledTextField
                  name="motherChipNumber"
                  label="母晶片號"
                  control={control}
                  defaultValue={!!couple.motherChipNumber ? couple.motherChipNumber : ""}
                  rules={{ pattern: /^\d{14}$/, required: true }}
                />
              </form>
            )}
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CoupleEdit;
