import { jsPDF } from "jspdf";

function GenerateContract(trade, store, setProcessingDownload, setAnchorEl) {
  import("../kai.js").then(() => {
    // console.debug("trade", trade);
    // console.debug("store", store);
    var totalPrice = trade.trade.totalPrice;
    var tempTotalPrice = totalPrice;
    var price1 = Math.floor(tempTotalPrice / 100000);
    tempTotalPrice -= price1 * 100000;
    var price2 = Math.floor(tempTotalPrice / 10000);
    tempTotalPrice -= price2 * 10000;
    var price3 = Math.floor(tempTotalPrice / 1000);
    tempTotalPrice -= price3 * 1000;
    var price4 = Math.floor(tempTotalPrice / 100);
    tempTotalPrice -= price4 * 100;
    var price5 = Math.floor(tempTotalPrice / 10);
    tempTotalPrice -= price5 * 10;
    var price6 = Math.floor(tempTotalPrice);

    var deposit = trade.trade.deposit;
    var tempDeposit = deposit;
    var depositPrice1 = Math.floor(tempDeposit / 100000);
    tempDeposit -= depositPrice1 * 100000;
    var depositPrice2 = Math.floor(tempDeposit / 10000);
    tempDeposit -= depositPrice2 * 10000;
    var depositPrice3 = Math.floor(tempDeposit / 1000);
    tempDeposit -= depositPrice3 * 1000;
    var depositPrice4 = Math.floor(tempDeposit / 100);
    tempDeposit -= depositPrice4 * 100;
    var depositPrice5 = Math.floor(tempDeposit / 10);
    tempDeposit -= depositPrice5 * 10;
    var depositPrice6 = Math.floor(tempDeposit);

    var payDate = trade.trade.payDate.split("-");
    var payYear = "",
      payMonth = "",
      payDay = "";
    if (payDate.length === 3) {
      payYear = payDate[0] - 1911;
      payMonth = payDate[1];
      payDay = payDate[2];
    }
    var receiveDate = trade.trade.receiveDate.split("-");
    var receiveYear = "",
      receiveMonth = "",
      receiveDay = "";
    if (receiveDate.length === 3) {
      receiveYear = receiveDate[0] - 1911;
      receiveMonth = receiveDate[1];
      receiveDay = receiveDate[2];
    }
    const doc = new jsPDF({});
    const rightMargin = 10;
    const indent1 = 9;
    const lineHeight = 7;
    const pageNumY = 280;
    var currentY = 0;
    doc.setFont("kai", "normal");
    // var font = doc.getFont();
    // console.debug("font", font);
    // console.log(doc.getFontList());
    doc.setFontSize(20);
    doc.text("特定寵物買賣定型化契約書", 60, 17);
    doc.setFontSize(14);
    doc.setLineHeightFactor(1.5);
    currentY = 25;
    doc.text(
      [
        `立契約書出賣人  ${store.name}  （以下簡稱甲方），`,
        `買受人  ${trade.buyer.name}  （以下簡稱乙方）`,
        `茲為下列特定寵物買賣事宜，雙方同意簽訂本契約，協議條款如下:`,
      ],
      rightMargin,
      currentY
    );
    currentY += lineHeight * 4;
    doc.text(`第1條：買賣標的`, rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      [
        `甲乙雙方買賣之特定寵物為動物保護法第3條第5款之所指犬、貓。`,
        `特定寵物種類: 貓   出生日期：${trade.pet.birthday}    晶片號碼：${trade.pet.chipNumber}`,
        `特定寵物性別: ${trade.pet.gender}   顏色：${trade.pet.color}    品種：${trade.pet.breed} `,
      ],
      rightMargin + indent1,
      currentY
    );
    currentY += lineHeight * 4;
    doc.text(`第2條：買賣價金及契約效力`, rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      [
        `1.本買賣總價款為新臺幣  ${price1} 拾 ${price2} 萬 ${price3} 仟 ${price4} 佰 ${price5} 拾 ${price6} 元整。`,
        "2.乙方支付前項買賣價金之方式如下：",
        `（1）乙方於民國  ${deposit <= 0 ? payYear : ""}  年  ${deposit <= 0 ? payMonth : ""}  月  ${
          deposit <= 0 ? payDay : ""
        }  日支付全部買賣價金。`,
        `（2）乙方於民國  ${deposit > 0 ? payYear : ""}  年  ${deposit > 0 ? payMonth : ""}  月  ${deposit > 0 ? payDay : ""}  日支付定金`,
        `新臺幣 ${deposit > 0 ? depositPrice1 : ""} 拾 ${deposit > 0 ? depositPrice2 : ""} 萬 ${deposit > 0 ? depositPrice3 : ""} 仟 ${
          deposit > 0 ? depositPrice4 : ""
        } 佰 ${deposit > 0 ? depositPrice5 : ""} 拾 ${deposit > 0 ? depositPrice6 : ""} 元，並應於 ${
          trade.trade.balanceDue > 0 ? trade.trade.balanceDue : ""
        } 日內付完餘款。`,
        "3.乙方如依前項第2款所定之方式支付定金者，在未完成餘款交付之期間，如該寵",
        "  物染患疾病或死亡，甲方應盡告知義務，得退還乙方所付之定金或經乙方同意",
        "  以另一寵物替代之。",
        "4.自甲方完成交付本契約所訂之買賣標的物（以下稱標的寵物），並由乙方將買",
        "  賣價金全數付訖後，始生本契約所訂標的寵物所有權轉移之效力。",
      ],
      rightMargin + indent1,
      currentY
    );
    currentY += lineHeight * 12;
    doc.text(`第3條：寵物責任擔保條款`, rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      [
        `1.本契約所訂之標的寵物於民國  ${receiveYear}  年  ${receiveMonth}  月  ${receiveDay}  日由乙方受領完畢。`,
        "2.標的寵物交付後，乙方應遵守甲方所指導之方式飼養，並定期至政府核定之合",
        "  格動物醫院（以下簡稱合格動物醫院）進行驅蟲、施打預防注射及完成寵物登",
        "  記作業。",
        "3.乙方自標的寵物交付後之48小時內，如該寵物經診斷患有發高燒、持續性下痢",
        "  或嘔吐、肺炎病症，並經合格動物醫院開立診斷證明書，甲方應無條件將標的",
        "  寵物帶回照顧，或更換標的寵物同等值之寵物予乙方，逾期應由乙方自行負",
        "  責。如檢查後發現該標的寵物有輕微之異常症狀，應即刻告知甲方，與甲方協",
        "  調後續處理。",
        "4.乙方自標的寵物交付之日起15天內，該寵物罹患狂犬病、犬瘟熱、犬小病毒；",
        "  或該寵物帶回7天內罹患出血性腸炎、犬冠狀病毒性腸炎及貓傳染性腹膜炎疾病",
        "  或貓瘟、貓愛病滋病、貓白血及貓卡里西病毒時，經由合格動物醫院開立診斷",
      ],
      rightMargin + indent1,
      currentY
    );
    doc.text(`1`, 104, pageNumY); //page number in middle of A4
    doc.addPage();
    currentY = 17;
    doc.text(
      [
        "  證明書，甲方應無條件將標的寵物帶回照顧，或更換標的寵物同等值之寵物予",
        "  乙方。",
        "  標的寵物若符合第3 、 4 款擔保內容，應通知甲方，並將標的寵物交由甲方指",
        "  定醫院進行後續處理。",
        "5.乙方應依甲方建議時間帶標的寵物至合格動物醫院施打預防針，但施打預防針",
        "  後，所產生之任何疾病，由乙方及該動物醫院負責。",
        "6.自標的寵物交付之日起180日內，經由合格動物醫院診斷證明患有肝門靜脈分",
        "  流、水腦；先天性或遺傳性癲癇、先天性膝蓋骨異位、先天性髖關節疾病，甲",
        "  方應更換標的寵物同等值之寵物予乙方，該患病寵物由甲方帶回照顧。",
        "7.自標的寵物交付之日起1年內，經由合格動物醫院診斷證明患有先天性或遺傳性",
        "  心臟病、青光眼、白內障、犬遺傳性視網膜退化症PRA或耳聾疾病，甲方應更",
        "  換同等值之寵物予乙方，該患病寵物交由甲方帶回照顧。",
        "8.乙方如未依前7款約定辦理，標的寵物均視為健康狀況正常，爾後標的寵物所發",
        "  生之疾病均與甲方無關，乙方應自行負責。",
      ],
      rightMargin + indent1,
      currentY
    );
    currentY += lineHeight * 16;
    doc.text(`第4條：權利義務`, rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      [
        "甲方應依動物保護法第22條之2第2項規定，將下列登載寵物相關資訊文件，提供",
        "予乙方：",
        "1.寵物登記證明。",
        "2.特定寵物飼養管理須知（附件1）。",
        "3.最近3個月內，獸醫師開立之特定寵物檢驗證明書(附件2)或診斷書(附件3)。",
        "4.來源母犬或貓之晶片號碼。",
        "乙方購買特定寵物前應清楚了解動物保護法所規範飼主責任相關規定(附件4)。",
      ],
      rightMargin + indent1,
      currentY
    );
    currentY += lineHeight * 8;
    doc.text("第5條：責任除外條款", rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      ["凡屬天然災害、意外傷害、及其他不可抗力或因飼養不當人為因素所造成之標的", "寵物死亡或疾病，不適用於本契約所訂之責任擔保範圍內。"],
      rightMargin + indent1,
      currentY
    );
    currentY += lineHeight * 3;
    doc.text("第6條：價金給付或標的物受領遲延之責任", rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      ["乙方應按時給付甲方買賣價金，乙方如有給付價金或受領標的寵物遲延，經甲方", "催告而乙方仍不履行時，甲方得解除本契約，沒收乙方已給付之價金。"],
      rightMargin + indent1,
      currentY
    );
    currentY += lineHeight * 3;
    doc.text("第7條：契約之解除", rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      ["乙方支付定金後，如不願買受標的寵物，得拋棄定金，解除本契約。", "甲方如不願出售標的寵物時，得加倍返還乙方所支付之定金後，解除本契約。"],
      rightMargin + indent1,
      currentY
    );
    doc.text("2", 104, pageNumY); //page number in middle of A4
    doc.addPage();
    currentY = 17;
    doc.text("第8條：其他規定事項", rightMargin, currentY);
    currentY += lineHeight;
    doc.text(["本契約訂立後，若有未盡事宜需增刪修改，經甲、乙雙方本著誠信公平原則或依", "民法及相關法令，修改之。"], rightMargin + indent1, currentY);
    currentY += lineHeight * 3;
    doc.text("第9條：爭議調解及合意管轄", rightMargin, currentY);
    currentY += lineHeight;
    doc.text(
      [
        "因本契約所發生之爭議，甲、乙雙方合意由新北市寵物商業同業公會之調解委員",
        "會進行調解，若未能調解，甲、乙雙方合意再由臺灣新北地方法院為第一審管轄",
        "法院進行訴訟。",
      ],
      rightMargin + indent1,
      currentY
    );
    currentY += lineHeight * 4;
    doc.text("第10條：契約份數", rightMargin, currentY);
    currentY += lineHeight;
    doc.text(["本契約壹式2份，由甲乙雙方各執1份為憑。"], rightMargin + indent1, currentY);
    currentY += lineHeight * 5;
    doc.text("立契約書人", rightMargin, currentY);
    currentY += lineHeight * 2;
    doc.text(
      [
        "甲方(出賣人)",
        "負責人姓名：                               （簽章）",
        `店家名稱：${store.name}`,
        `營業地址：${store.address}`,
        `聯絡電話：${store.phone}`,
        `特定寵物業許可證字號：${store.license}`,
        `公司/商業登記證號：${store.registration}`,
      ],
      rightMargin,
      currentY
    );

    currentY += lineHeight * 10;
    doc.text(
      [
        "乙方(買受人)",
        "姓名：                                    （簽章）",
        `聯絡電話：${trade.buyer.phone}`,
        `身分證字號：${trade.buyer.idNumber}`,
        `住址：${trade.buyer.address}`,
      ],
      rightMargin,
      currentY
    );
    currentY += lineHeight * 7;
    doc.text("中 華 民 國      年    月    日", 67, currentY);
    doc.text("3", 104, pageNumY); //page number in middle of A4
    doc.save(`寵物買賣契約書-${trade.pet.chipNumber}.pdf`);
    setProcessingDownload(false)
    setAnchorEl(null)
  });
}
export default GenerateContract;
