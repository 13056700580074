import "./Main.scss";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import AppBar from "./AppBar";
function Info() {
  useEffect(() => {
    document.querySelector("body").style.background = "#ffffff";
  }, []);

  return (
    <Box sx={{ p: 0, maxWidth: "1200px", margin: "auto" }}>
      <Helmet>
        <title>寵理才</title>
      </Helmet>
      <Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={12}>
          <AppBar />
          <Outlet />
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
}
export default Info;
