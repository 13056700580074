
import { Box, Typography } from "@mui/material";
function TermsOfService(){
	return(
		<Box sx={{ p: 4 }}>
		<Typography variant="h4" gutterBottom>
		  服務條款
		</Typography>
		<Typography variant="h5" gutterBottom>
		  1. 引言
		</Typography>
		<Typography variant="body1" gutterBottom>
		  請仔細閱讀本使用條款 (「本條款」)，因為本條款規範您對寵理才及其他內容的個人化服務之使用。
		</Typography>
		<Typography variant="body1" gutterBottom>
		  寵理才服務之使用可能受 寵理才所提供的其他條款與條件約束，此等條款與條件特此併入本條款。
		</Typography>
		<Typography variant="body1" gutterBottom>
		  註冊或以其他方式使用 寵理才服務即表示您同意本條款。如果您不同意本條款，則不得使用 寵理才服務或存取任何內容。
		</Typography>
		<Typography variant="body1" gutterBottom>
		  本條款適用於您與權輿科技股份有限公司之間的關係。
		</Typography>
		<Typography variant="body1" gutterBottom>
		  為了使用 寵理才服務與存取內容，您必須：(1) 年滿 13 歲 (或您所在國家/地區的同等最低年齡)
		  或以上，(2)若您為所在國家/地區的未成年人，則需要父母或監護人同意；(3) 有權與我們簽署具約束力的合約，且不受任何適用法律的禁止；以及
		  (4)居住於有提供本服務的國家/地區。您也保證提交給寵理才的註冊資訊屬實、正確且完整，並同意始終保持該狀態。若您為所在國家/地區的未成年人，則需要父母或監護人代表您簽訂本條款。您可以在註冊程序中找到有關最低年齡要求的其他資訊。如果您不符合最低年齡要求寵理才將無法將您註冊為使用者。
		</Typography>
		<br />
		<Typography variant="h5" gutterBottom>
		  2. 我們提供的服務
		</Typography>
		<Typography variant="h6" gutterBottom>
		  服務選項
		</Typography>
		<Typography variant="body1" gutterBottom>
		  我們提供一種服務選項，需要先付款才能存取 (「付費訂用」)。我們也可能提供特殊促銷方案、不同會員資格或服務。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  試用
		</Typography>
		<Typography variant="body1" gutterBottom>
		  我們或代表我們的其他人可能偶爾於特定時期內，提供付費訂用的免費試用或減價優惠試用 (「試用」)。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  服務限制與修改
		</Typography>
		<Typography variant="body1" gutterBottom>
		  我們會以合理程度的謹慎和技術維持寵理才服務運作。然而，我們的服務項目及其供應狀況可能隨時變更，並受適用法律規範，對您不需負擔任何責任；例如：
		  <br />
		  寵理才服務可能因技術困難、維護或測試或更新 (包括為反映相關法律與監管要求變更所需) 而暫時中斷。
		  <br />
		  我們的目標是持續改進服務。我們可能會修改、暫停或中止 (永久或暫時) 提供所有或部分的寵理才服務 (包括特定功能、特色、訂閱方案和促銷方案)。
		  <br />
		  寵理才沒有義務透過寵理才服務提供任何特定內容，且寵理才或適用的擁有者得修改、新增或移除特定功能。
		  <br />
		  若您已向寵理才預付付費訂用之費用，但寵理才於預付期間
		  (如以下「付款與取消」一節所定義)結束之前永久中止，寵理才將針對您目前付費訂用之任何未使用的部分，於該中止後退還預付期間的預付費用。您的帳戶和帳單資訊必須維持最新狀態，我們才能為您退款。
		  <br />
		  對於與網際網路或其他因政府主管機關、其他第三方或我們無法控制的事件所造成的服務中斷或故障，寵理才對您不負任何責任，亦無任何向您提供退款的義務。
		</Typography>
		<br />
		<Typography variant="h5" gutterBottom>
		  3. 您對寵理才服務的使用
		</Typography>
		<Typography variant="h6" gutterBottom>
		  建立寵理才帳戶
		</Typography>
		<Typography variant="body1" gutterBottom>
		  您可能需要建立
		  寵理才帳戶，才能使用寵理才服務的全部或部分內容。您的使用者名稱和密碼僅供個人使用，應予以保密。您瞭解您必須對使用者名稱與密碼的使用(包括任何未經授權的使用)負責。如果您的使用者名稱或密碼遺失或遭竊，或您認為帳戶遭未經授權存取，請立即通知我們的客戶服務團隊。
		  <br />
		  寵理才可能會基於任何原因要求收回您的使用者名稱，或要求您變更您的使用者名稱。
		  <br />
		</Typography>
		<Typography variant="h6" gutterBottom>
		  存取寵理才服務
		</Typography>
		<Typography variant="body1" gutterBottom>
		  在您遵守本條款 (包括任何其他適用的條款與條件) 的情況下，我們授予您有限、非專屬、可撤銷的許可，以供您就寵理才服務及內容進行使用
		  (統稱「存取權」)。本存取權持續有效，直至您或寵理才終止為止。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  寵理才之所有權
		</Typography>
		<Typography variant="body1" gutterBottom>
		  寵理才服務與內容皆為 寵理才或 寵理才之授權人的財產。所有
		  寵理才的商標、服務標誌、商業名稱、徽標、網域名稱以及寵理才品牌的其他特色(「寵理才品牌特色」)
		  為寵理才及其授權人的獨有財產。本條款並未授予您使用寵理才品牌特色的任何權利，無論是作商業或非商業之用。
		  <br />
		  您同意遵守寵理才使用者指南，並且不以本條款中未明確允許的任何方式使用 寵理才服務、內容或任何相關的部分。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  帳單
		</Typography>
		<Typography variant="body1" gutterBottom>
		  您可以透過下列方式直接從寵理才購買付費訂用：
		  <br />
		  每月事先支付訂用費用；或預先付款並獲得特定期間 (「預付期間」) 的寵理才服務存取權。
		  <br />
		  稅率係根據您提供的資訊以及每月向您收取費用時適用的費率計算。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  價格與稅金變更
		</Typography>
		<Typography variant="body1" gutterBottom>
		  寵理才可能不定期變更付費訂用的價格，包括週期性的訂用費用、預付期間 (針對尚未付費的期間) 或代碼 (如前文所定義)
		  的價格，並將在合理的時間事先通知您任何價格變更。價格變更將於價格變更日起的下一個訂用期開始生效。根據適用法律，在價格變更生效後繼續使用寵理才服務代表您接受新的價格。如果您不同意價格變更，您有權在價格變更生效之前，取消訂用付費訂用以拒絕變更。
		  <br />
		  稅率取決於每月向您收費時適用的費率。這些金額會隨著您所在國家/地區、州、領地，或甚至城市當地的稅務規定而變動。稅率的任何變更都會根據您提供的帳戶資訊自動適用。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  續訂與取消
		</Typography>
		<Typography variant="body1" gutterBottom>
		  除了預付期間的付費訂用外，您對寵理才的費用，會在適用之訂用期間結束時自動續訂，除非您在目前訂用期間結束前取消付費訂用。如需如何取消的指示，請聯絡我們的客戶支援團隊。取消將於目前訂用期間的最後一日之後生效，您將無法繼續使用寵理才的服務。除非本條款另有明文規定，否則我們不提供訂用期間任何部分的退款或抵用額度。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  退出權利
		</Typography>
		<Typography variant="body1" gutterBottom>
		  如果您購買了付費訂用，您同意您在購買後的十四 (14)
		  天內有權因任何原因退出，且必須支付在您通知我們改變心意前所提供之服務費用。您明確同意我們在您購買後立即向您提供服務，使您失去退出權利，並授權寵理才每月自動向您收取費用，直到您取消為止。
		</Typography>
		<br />
		<Typography variant="h5" gutterBottom>
		  4. 內容
		</Typography>
		<Typography variant="h6" gutterBottom>
		  使用者內容
		</Typography>
		<Typography variant="body1" gutterBottom>
		  發布使用者內容至本服務時，您仍保有其所有權。然而，為讓我們在寵理才服務上提供您的使用者內容，我們需要您授予我們該使用者內容的有限權利。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  回饋
		</Typography>
		<Typography variant="body1" gutterBottom>
		  您所提供有關使用寵理才服務或任何內容的想法、建議或其他回饋 (「回饋」)
		  並不具機密性，寵理才可無限制使用回饋而不需向您支付費用。根據本條款，回饋視為一種使用者內容。
		</Typography>
		<Typography variant="h6" gutterBottom>
		  您的裝置
		</Typography>
		<Typography variant="body1" gutterBottom>
		  您也授予我們以下權利：(1) 允許寵理才服務使用您裝置的處理器、頻寬和儲存硬體，以協助 寵理才服務運作，(2)
		  為您提供廣告和其他資訊，並根據寵理才隱私權政策允許我們的業務夥伴亦如此行事。
		</Typography>
		<br />
		<Typography variant="h5" gutterBottom>
		  5. 客戶支援、資訊、問題和投訴
		</Typography>
		<Typography variant="body1" gutterBottom>
		  對於帳戶相關與付款相關之問題的客戶支援 (「客戶支援詢問」)，或是對於寵理才服務或本條款 (包括併入本條款的其他寵理才條款與條件)
		  有任何疑問，請聯繫support@budylon.com。
		</Typography>
		<br />
		<Typography variant="h5" gutterBottom>
		  6. 準據法與管轄法院
		</Typography>
		<Typography variant="body1" gutterBottom>
		  本服務條款之解釋與適用，以及與本服務條款有關或會員與寫字的力量間因交易行為而產生之爭議或糾紛，應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院，但若法律對於管轄法院另有強制規定者，仍應依其規定。
		</Typography>
		<Typography variant="body1" gutterBottom></Typography>
		<Typography variant="body1" gutterBottom></Typography>
		<Typography variant="body1" gutterBottom></Typography>
		<Typography variant="body1" gutterBottom></Typography>
		<Typography variant="body1" gutterBottom></Typography>
	  </Box>
	)
}
export default TermsOfService;