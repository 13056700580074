import { useState, useContext, useEffect } from "react";
import {Box, Link, Container, Grid ,Typography ,IconButton , Breadcrumbs } from "@mui/material";
import {Table, TableBody, TableCell, TableContainer ,TableHead ,TableRow , TablePagination } from "@mui/material";
import {Button, TextField, Paper, Menu, MenuItem, ListItemText, ListItemIcon, Skeleton} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import DeleteAlert from "../component/DeleteAlert";
function createData(id, fatherName, motherName, fatherChipNumber, motherChipNumber) {
  return { id, fatherName, motherName, fatherChipNumber, motherChipNumber };
}

// const rows = [
//   createData(1, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(2, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(3, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(4, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(5, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(6, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(7, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(8, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(9, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(10, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(11, "志強", "春嬌", "11111111111111", "22222222222222"),
// ];
const tableCellStyle = {
  fontSize: "16px",
  color: "var(--color-on-surface)",
  borderBottom: "1px solid var(--color-primary)",
  padding: { xs: 2 },
  whiteSpace: "nowrap",
};

const tablePaginationStyle = {
  height: "40px",
  // bgcolor:"var(--color-surface)",
  "& .MuiTablePagination-spacer": {
    flex: "0 1 auto",
  },
  "& .MuiTablePagination-toolbar": {
    height: "40px",
    minHeight: "40px",
    justifyContent: "flex-end",
  },
  "& .MuiTablePagination-selectLabel": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiInputBase-root": {
    marginRight: { xs: "16px", md: "32px" },
  },
  "& .MuiTablePagination-select": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    lineHeight: "32px",
    padding: "0px",
  },
  "& .MuiTablePagination-selectIcon": {
    color: "var(--color-on-surface)",
    fontSize: "24px",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiTablePagination-actions": {
    color: "var(--color-on-surface)",
    ml: { xs: "8px", md: "16px" },
  },
  "& .MuiTablePagination-actions .MuiSvgIcon-root": {
    fontSize: "24px",
  },
};

function CoupleList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = useState(matches ? 10 : 5);
  const [page, setPage] = useState(0);
  const [currentCoupleId, setCurrentCoupleId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [unfilteredRows, setUnfilteredRows] = useState([]);
  const appContext = useContext(AppContext);
  let navigate = useNavigate();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/coupleLists?storeId=${appContext.storeId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data !== null) {
            var tempRows = [];
            for (var couple of data) {
              tempRows.push(
                createData(couple.id, couple.malePet.name, couple.femalePet.name, couple.malePet.chipNumber, couple.femalePet.chipNumber)
              );
            }
            setUnfilteredRows(tempRows);
            setRows(tempRows);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Fetch couple list error", error);
          setLoading(false);
        });
    }
  }, []);

  const handleMenuClick = (event, id) => {
    setCurrentCoupleId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setCurrentCoupleId(null);
    setAnchorEl(null);
  };
  const handleViewCouple = () => {
    var id = currentCoupleId;
    setCurrentCoupleId(null);
    setAnchorEl(null);
    navigate(`/dashboard/couplelist/${id}`);
  };
  const handleAddChild = () => {
    setCurrentCoupleId(null);
    setAnchorEl(null);
    navigate(
      `/dashboard/couplelist/createchild?fatherChipNumber=${rows.find((row) => row.id === currentCoupleId).fatherChipNumber}&motherChipNumber=${
        rows.find((row) => row.id === currentCoupleId).motherChipNumber
      }&from="coupleList`
    );
  };
  const handleEditCouple = () => {
    var id = currentCoupleId;
    setCurrentCoupleId(null);
    setAnchorEl(null);
    navigate(`/dashboard/couplelist/edit/${id}?from=coupleList`);
  };
  const handleDeleteCouple = () => {
    var coupleId = currentCoupleId;
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/coupleList/${coupleId}?storeId=${appContext.storeId}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          var tempRows = rows.filter((row) => row.id !== coupleId);
          setRows(tempRows);
          var tempunfilteredRows = unfilteredRows.filter((row) => row.id !== coupleId);
          setUnfilteredRows(tempunfilteredRows);
        })
        .catch((error) => {
          console.error("Delete couple error", error);
        });
    }
    setCurrentCoupleId(null);
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCreate = () => {
    navigate(`/dashboard/couplelist/create`);
  };
  const handleSearch = (event) => {
    var tempRows = unfilteredRows.filter(
      (row) =>
        row.motherName.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.fatherName.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.motherChipNumber.includes(event.target.value) ||
        row.fatherChipNumber.includes(event.target.value)
    );
    // var myFinalArray = [...new Set([...motherNameTempRows, ...fatherNameTempRows, ...motherChipTempRows, ...fatherChipTempRows])];
    setRows(tempRows);
    setPage(0);
  };
  //   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>配種清單 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle}>
            <Link underline="none">
              <FeaturedPlayListIcon sx={{ mr: 1 }} />
              配種清單
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap " }}>
            <Button variant="outlined" onClick={handleCreate} startIcon={<AddCircleIcon />}>
              新增
            </Button>
            <Button variant="outlined" startIcon={<FilterAltIcon />} sx={{ display: "none" }}>
              篩選
            </Button>
            <Box id="search-bar">
              <TextField
                id="search-input-field"
                label="名稱或晶片號碼"
                variant="outlined"
                InputLabelProps={{ size: "small" }}
                color="primary"
                onChange={handleSearch}
                sx={{
                  width: "250px",
                  height: "40px",
                  fontSize: "12px",
                  "& label.MuiInputLabel-root": {
                    color: "#aaa",
                  },
                  "& label.Mui-focused": {
                    color: "var(--color-primary)",
                  },
                  "& .MuiInputBase-input": { padding: 1, color: "var(--color-on-surface)" },
                  "& .MuiOutlinedInput-root": {
                    color: "var(--color-on-surface)",
                    "& fieldset": {
                      borderColor: "#aaa",
                    },
                    "&:hover fieldset": {
                      borderColor: "var(--color-on-surface)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--color-primary)",
                    },
                  },
                }}
              />
              <IconButton aria-label="search" disabled={true} sx={{ "&:hover": { bgcolor: "var(--color-surface)" }, borderRadius: "8px" }}>
                <SearchIcon fontSize="medium" sx={{ color: "var(--color-on-surface)" }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          {loading ? (
            <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={118} />
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "var(--color-surface)",
                borderRadius: "8px",
                // minWidth: "300px",
                margin: "0px auto",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "var(--color-surface)",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Menu
                  id="pet-item-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "& .MuiMenu-paper": { backgroundColor: "var(--color-surface-variant)", color: "var(--color-on-surface)" },
                    "& .MuiListItemIcon-root": { color: "var(--color-on-surface)" },
                  }}
                >
                  <MenuItem onClick={handleViewCouple}>
                    <ListItemIcon>
                      <ManageSearchIcon />
                    </ListItemIcon>
                    <ListItemText>檢視</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleAddChild}>
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText>新增子嗣</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleEditCouple}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>編輯</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowDeleteAlert(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>刪除</ListItemText>
                  </MenuItem>
                </Menu>
                <DeleteAlert
                  showDeleteAlert={showDeleteAlert}
                  handleDelete={handleDeleteCouple}
                  handleClose={() => {
                    handleMenuClose();
                    setShowDeleteAlert(false);
                  }}
                />
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" sx={{ ...tableCellStyle, maxWidth: "30px" }}></TableCell>
                      <TableCell sx={{ ...tableCellStyle, minWidth: "100px" }}>名字</TableCell>
                      <TableCell sx={tableCellStyle}>晶片號碼</TableCell>
                      <TableCell sx={tableCellStyle}>性別</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell sx={{ ...tableCellStyle, width: "30px", pr: { xs: 0 } }}>
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={(e) => handleMenuClick(e, row.id)}
                            sx={{ mr: 0 }}
                          >
                            <MoreHorizIcon sx={{ width: "24px", height: "24px" }} />
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ ...tableCellStyle, minWidth: "100px" }}>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>{row.fatherName}</Box>
                            <Box>{row.motherName}</Box>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ ...tableCellStyle, fontFamily: "monospace" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", fontFamily: "monospace" }}>
                            <Box>{row.fatherChipNumber}</Box>
                            <Box>{row.motherChipNumber}</Box>
                          </Box>
                        </TableCell>
                        <TableCell sx={tableCellStyle}>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography sx={{}}>公</Typography>
                            <Typography>母</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 80 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={5} sx={{ borderBottom: "1px solid var(--color-primary)" }} />
                    </TableRow>
                  )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="每頁顯示"
                labelDisplayedRows={({ from, to, count }) => `${from} - ${to} / ${count}`}
                sx={tablePaginationStyle}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default CoupleList;
