import { useContext, useState, useEffect } from "react";
//mui
import { Box, Grid, Container, Breadcrumbs, Alert, Link, Button } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppContext } from "../AppContext";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet";
import PasswordTextfield from "../component/PasswordTextfield";
import { getAuth, updatePassword } from "firebase/auth";

function User() {
  const appContext = useContext(AppContext);
  const {
    control,
    handleSubmit,
    setError,
    // formState: { errors },
    formState,
  } = useForm();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const auth = getAuth();
  useEffect(() => {
    if (Object.keys(formState.errors).length !== 0) {
      setShowSuccessAlert(false);
    }
  }, [formState]);

  const user = auth.currentUser;
  const onSubmit = (data) => {
    setShowSuccessAlert(false);
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      if (data.newPassword === "" || data.confirmNewPassword === "") {
        setError("confirmNewPassword", { type: "format", message: "密碼不能為空" });
        return;
      }

      if (data.newPassword !== data.confirmNewPassword) {
        setError("confirmNewPassword", { type: "format", message: "再次輸入新密碼需與新密碼相同" });
        return;
      }
      updatePassword(user, data.newPassword)
        .then(() => {
          setShowSuccessAlert(true);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/requires-recent-login":
              setError("newPassword", { type: "format", message: "密碼更新失敗，請重新登入再嘗試" });
              break;
            default:
              setError("newPassword", { type: "format", message: "密碼更新失敗，請重新登入再嘗試" });
              break;
          }
          console.log("Password Update Failed", error);
        });
    }
  };
  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>使用者管理 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon fontSize="medium" />}>
            <Link underline="none">
              <AccountCircleIcon sx={{ mr: 1 }} />
              使用者管理
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
              <Button variant="outlined" onClick={handleSubmit(onSubmit)} startIcon={<SaveIcon />}>
                修改密碼
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Container
            sx={{
              bgcolor: "var(--color-surface)",
              width: { xs: 282, sm: 564 },
              padding: 2,
              borderRadius: "8px",
            }}
          >
            {showSuccessAlert && (
              <Alert severity="success" sx={{ mb: 2 }}>
                修改成功
              </Alert>
            )}
            {Object.values(formState.errors).map((error) => (
              <Alert key={error.message} severity="error" sx={{ mb: 2 }}>
                {error?.message}
              </Alert>
            ))}

            <Controller
              name="newPassword"
              label="新密碼"
              control={control}
              defaultValue=""
              rules={{ minLength: { value: 6, message: "需為數字或大小寫英文字母且至少6個字元" } }}
              render={({ field }) => <PasswordTextfield field={field} label="新密碼" />}
            />
            <Controller
              name="confirmNewPassword"
              label="再次輸入新密碼"
              control={control}
              defaultValue=""
              rules={{ minLength: { value: 6, message: "需為數字或大小寫英文字母且至少6個字元" } }}
              render={({ field }) => <PasswordTextfield field={field} label="再次輸入新密碼" />}
            />
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}

export default User;
