import "./HomePage.scss";
import { useState, useEffect, useContext } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import { styled } from "@mui/material/styles";
import zhTW from "date-fns/locale/zh-TW";
import DueDateTable from "../component/DueDateTable";
import { AppContext } from "../AppContext";
import { Helmet } from "react-helmet";
import VaccinationDateTable from "../component/VaccinationDateTable";
const CustomPickersDay = styled(PickersDay)(({ theme }) => ({
  backgroundColor: "var(--color-surface)",
  color: "var(--color-on-surface)",
  margin: "0px auto",
  fontSize: 16,
  display: "block",
  "&.Mui-selected": {
    backgroundColor: "var(--color-primary)",
  },
  "&:focus.Mui-selected": {
    backgroundColor: "var(--color-primary)",
  },
  "&:hover.Mui-selected": {
    backgroundColor: "var(--color-primary-variant)",
  },
  "&.Mui-disabled": {
    // backgroundColor: "var(--color-primary)",
    color: "var(--color-on-surface)",
  },
  "&:focus.Mui-disabled": {
    backgroundColor: "var(--color-primary)",
  },
  "&:hover.Mui-disabled": {
    backgroundColor: "var(--color-primary-variant)",
  },
  "&.MuiPickersDay-dayOutsideMonth": {
    color: "var(--color-background)",
  },
  //   flexGrow: 1,
}));

function HomePage() {
  const [importantDates, setImportantDates] = useState([]);
  const [duePetList, setDuePetList] = useState([]);
  const [vaccinationList, setVaccinationList] = useState([]);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;

      var headers = {
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      };

      Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/duedate?storeId=${appContext.storeId}&today=${today}`, headers),
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/vaccineDate?storeId=${appContext.storeId}&today=${today}`, headers),
      ])
        .then((responses) => {
          var _importantDates = [];
          if (responses[0].ok) {
            responses[0].json().then((data) => {
              // console.log(data);
              if (data !== null) {
                var duePetList = [];
                for (const pet of data) {
                  if (pet.dueDate !== "") {
                    var dueDate = new Date(pet.dueDate + "T00:00:00");
                    _importantDates.push(dueDate.getTime()); //to unix timestamp
                    duePetList.push(pet);
                  }
                }
                setDuePetList(duePetList);
              }
            });
          }
          if (responses[1].ok) {
            responses[1].json().then((data) => {
              // console.log(data);
              if (data !== null) {
                var vaccinationList = [];
                for (const pet of data) {
                  if (pet.rabiesVaccination !== "" || pet.generalVaccination !== "") {
                    if (pet.rabiesVaccination !== "") {
                      let vaccinationDate = new Date(pet.rabiesVaccination + "T00:00:00");
                      let vaccineObject = {};

                      vaccineObject.id = pet.id;
                      vaccineObject.name = pet.name;
                      vaccineObject.photoUrl = pet.photoUrl;
                      vaccineObject.date = pet.rabiesVaccination;
                      vaccineObject.vaccine = "狂犬病接種";
                      _importantDates.push(vaccinationDate.getTime());
                      vaccinationList.push(vaccineObject);
                    }
                    if (pet.generalVaccination !== "") {
                      let vaccinationDate = new Date(pet.generalVaccination + "T00:00:00");
                      let vaccineObject = {};

                      vaccineObject.id = pet.id;
                      vaccineObject.name = pet.name;
                      vaccineObject.photoUrl = pet.photoUrl;
                      vaccineObject.date = pet.generalVaccination;
                      vaccineObject.vaccine = "預防針接種";
                      _importantDates.push(vaccinationDate.getTime());
                      vaccinationList.push(vaccineObject);
                    }
                  }
                }
                setVaccinationList(vaccinationList);
              }
            });
          }
          setImportantDates(_importantDates);
        })
        .catch((error) => {
          console.error("Fetch error", error);
        });
    }
    // setDueDates([new Date(2022, 6, 4), new Date(2022, 6, 2), new Date(2022, 7, 1)]); // for testing
  }, []);
  const renderWeekPickerDay = (date, selectedDays, pickersDayProps) => {
    const dateTime = date.getTime();
    const selected = importantDates.find((item) => item === dateTime);
    return (
      <div style={{ flexGrow: 1 }} key={date}>
        <CustomPickersDay {...pickersDayProps} disabled={!!!selected} selected={!!selected} disableHighlightToday={true} />
      </div>
    );
  };
  zhTW.options.weekStartsOn = 0;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
      <Container sx={{ pb: 3 }}>
        <Helmet>
          <title>首頁 - 寵理才</title>
        </Helmet>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={8} md={12}>
            <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle}>
              <Link underline="none">
                <HomeIcon  sx={{ mr: 1 }}/>
                首頁
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} sm={6} md={6}>
            <Box sx={{ backgroundColor: "var(--color-surface)", borderRadius: "8px", maxWidth: "500px", minWidth: "300px", margin: "0px auto" }}>
              <Typography sx={{ fontSize: "16px", padding: "10px 16px 0px 16px", color: "var(--color-on-surface)", width: "100%" }}>
                提醒事項月曆
              </Typography>
              <CalendarPicker className="date-picker" renderDay={renderWeekPickerDay} onChange={() => {}} showDaysOutsideCurrentMonth={true} />
            </Box>
          </Grid>
          <Grid item xs={4} sm={6} md={6}>
            <Box sx={{ backgroundColor: "var(--color-surface)", borderRadius: "8px", maxWidth: "500px", minWidth: "300px", margin: "0px auto" }}>
              <Typography sx={{ fontSize: "16px", padding: "10px 16px 0px 16px", color: "var(--color-on-surface)", width: "100%" }}>
                待產日提醒
              </Typography>
              <DueDateTable duePetList={duePetList} />
            </Box>
          </Grid>
          <Grid item xs={4} sm={6} md={6}>
            <Box sx={{ backgroundColor: "var(--color-surface)", borderRadius: "8px", maxWidth: "500px", minWidth: "300px", margin: "0px auto" }}>
              <Typography sx={{ fontSize: "16px", padding: "10px 16px 0px 16px", color: "var(--color-on-surface)", width: "100%" }}>
                疫苗施打日提醒
              </Typography>
              <VaccinationDateTable vaccinationList={vaccinationList} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
}

export default HomePage;
