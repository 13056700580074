import { useContext, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LogoutIcon from "@mui/icons-material/Logout";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StoreIcon from "@mui/icons-material/Store";
import profileImage from "./profile.png";
import { AppContext } from "../AppContext";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const MenuIconStyle = {
  color: "var(--color-on-surface)",
};

const drawerWidth = 260;

function LeftMenu({ mobileOpen, handleDrawerToggle, setShowSaveAlert, setRedirectPath, storeName, setStoreName }) {
  const appContext = useContext(AppContext);
  let navigate = useNavigate();
  const auth = getAuth(appContext.app);
  function MenuListItem(props) {
    return (
      // <Link to={`/${props.route}`} style={{ fontSize: "16px", color: "var(--color-on-surface)", textDecoration: "none" }} onClick={props.handleDrawerToggle}>
      <ListItem
        key={props.text}
        disablePadding
        sx={{ fontSize: "16px", lineHeight: 1.5 }}
        onMouseDown={(e) => {
          if (e.button === 1) {
            //middle click
            if ("route" in props) {
              window.open(`/${props.route}`, "_blank", "noopener,noreferrer");
            }
            return;
          }
          if (!appContext.edited) {
            if ("handleClick" in props) {
              props.handleClick();
            }
          } else {
            setRedirectPath(`/${props.route}`);
            setShowSaveAlert(true);
          }
          if (props.handleDrawerToggle !== null) {
            props.handleDrawerToggle();
          }
        }}
      >
        <ListItemButton sx={{ gap: "16px", padding: "8px 32px", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" } }}>
          <ListItemIcon sx={{ minWidth: "30px" }}>{props.icon}</ListItemIcon>
          <ListItemText primary={props.text} disableTypography={true} sx={{ fontSize: "16px", color: "var(--color-on-surface)" }} />
        </ListItemButton>
      </ListItem>
      // </Link>
    );
  }
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/store/${appContext.storeId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data !== null) {
            appContext.store = data;
            setStoreName(data.name);
          }
        })
        .catch((error) => {
          console.error("Fetch store error", error);
        });
    }
  }, []);

  const drawer = (handleDrawerToggle) => (
    <Box sx={{ display: "flex", flexDirection: "column", width: 240, flexGrow: 1 }} role="presentation">
      <Box>
        <img src={profileImage} alt="profile" style={{ width: "60px", height: "60px", margin: "20px auto", display: "block" }} />
      </Box>
      <Typography variant="body1" sx={{ color: "var(--color-on-surface)", fontSize: "16px", textAlign: "center" }}>
        {storeName}
      </Typography>
      <Divider sx={{ borderColor: "var(--color-primary)", margin: "8px 16px" }} />
      <List sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <MenuListItem
          id="home-menu-item"
          text="首頁"
          route="dashboard/home"
          icon={<HomeIcon sx={MenuIconStyle} />}
          handleDrawerToggle={handleDrawerToggle}
          handleClick={(newWindow) => {
            navigate("/dashboard/home");
          }}
        />
        <MenuListItem
          id="petlist-menu-item"
          text="寵物清單"
          route="dashboard/petlist"
          icon={<FormatListBulletedIcon sx={MenuIconStyle} />}
          handleDrawerToggle={handleDrawerToggle}
          handleClick={() => {
            navigate("/dashboard/petlist");
          }}
        />
        <MenuListItem
          id="couplelist-menu-item"
          text="配種清單"
          route="dashboard/couplelist"
          icon={<FeaturedPlayListIcon sx={MenuIconStyle} />}
          handleDrawerToggle={handleDrawerToggle}
          handleClick={() => {
            navigate("/dashboard/couplelist");
          }}
        />
        <MenuListItem
          id="store-menu-item"
          text="買賣紀錄"
          route="dashboard/tradelist"
          icon={<ReceiptLongIcon sx={MenuIconStyle} />}
          handleDrawerToggle={handleDrawerToggle}
          handleClick={() => {
            navigate("/dashboard/tradelist");
          }}
        />
        <MenuListItem
          id="store-menu-item"
          text="商店管理"
          route="dashboard/store"
          icon={<StoreIcon sx={MenuIconStyle} />}
          handleDrawerToggle={handleDrawerToggle}
          handleClick={() => {
            navigate("/dashboard/store");
          }}
        />
        <MenuListItem
          id="user-menu-item"
          text="使用者管理"
          route="dashboard/user"
          icon={<AccountCircleIcon sx={MenuIconStyle} />}
          handleDrawerToggle={handleDrawerToggle}
          handleClick={() => {
            navigate("/dashboard/user");
          }}
        />
        <Box id="place-holder" sx={{ flexGrow: "1" }} />
        <MenuListItem
          id="logout-menu-item"
          text="登出"
          // route="login"
          icon={<LogoutIcon sx={MenuIconStyle} />}
          handleDrawerToggle={null}
          handleClick={() => {
            signOut(auth)
              .then(() => {
                appContext.signedIn = false;
                navigate("/login");
                // console.debug("Sign-out successful");
              })
              .catch((error) => {
                console.error("Sign-out fail,", error);
                // An error happened.
              });
          }}
        />
      </List>
    </Box>
  );
  return (
    <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }} aria-label="mailbox folders">
      <Drawer
        anchor="left"
        open={mobileOpen}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{ sx: { border: "none", bgcolor: "var(--color-surface)" } }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            width: "240px",
            boxSizing: "border-box",
            backgroundImage: "none",
          },
        }}
        onClose={handleDrawerToggle}
      >
        {drawer(handleDrawerToggle)}
      </Drawer>
      <Drawer
        variant="permanent"
        PaperProps={{ sx: { border: "none", bgcolor: "var(--color-surface)" } }}
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawer(null)}
      </Drawer>
    </Box>
  );
}

export default LeftMenu;
