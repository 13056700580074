import { useContext, useState, useEffect, useRef } from "react";
//mui
import Box from "@mui/material/Box";
import { Grid, Container, Breadcrumbs, IconButton, TextField, Typography, Avatar } from "@mui/material";
import { Button, Select, MenuItem, InputLabel, FormControl, Skeleton, Link, Alert } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { styled } from "@mui/material/styles";
//icon
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import zhTW from "date-fns/locale/zh-TW";
import { format } from "date-fns";
import { useLocation, useParams, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import Compressor from "compressorjs";
import { useForm, Controller } from "react-hook-form";
import catIcon from "./cat-icon.png";
import { AppContext } from "../AppContext";
import DeleteAlert from "../component/DeleteAlert";
import { Helmet } from "react-helmet";
const CustomTextField = styled(TextField)(({ theme }) => ({
  minWidth: "250px",
  height: "56px",
  "& label.MuiInputLabel-root": {
    color: "#aaa",
  },
  "& label.Mui-focused": {
    color: "var(--color-primary)",
  },
  "& .MuiInputBase-input": { padding: "0px 16px", color: "var(--color-on-surface)", height: "56px" },
  "& .MuiOutlinedInput-root": {
    color: "var(--color-on-surface)",
    "& fieldset": {
      borderColor: "#aaa",
    },
    "&:hover fieldset": {
      borderColor: "var(--color-on-surface)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-primary)",
    },
    "& textarea": {
      padding: "0px",
    },
  },
}));
function ControlledTextField(prop) {
  return (
    <Controller
      name={prop.name}
      control={prop.control}
      defaultValue={prop.defaultValue}
      rules={prop.rules}
      render={({ field }) => (
        <CustomTextField
          {...field}
          multiline={prop.multiline}
          sx={{ width:"250px", ...prop.sx }}
          label={prop.label}
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{ size: "normal" }}
          color="primary"
        />
      )}
    />
  );
}
function UncontrolledDatePicker(prop) {
  return (
    <Controller
      {...prop}
      render={({ field }) => (
        <DatePicker
          {...field}
          label={prop.label}
          openTo="day"
          views={["year", "month", "day"]}
          inputFormat="yyyy-MM-dd"
          renderInput={(params) => <TextField sx={{ width: "250px", "& fieldset": { borderColor: "rgb(192, 192, 192)" } }} {...params} />}
        />
      )}
    />
  );
}
function PetEdit(props) {
  const appContext = useContext(AppContext);
  const [showSaveAlert, setShowSaveAlert, redirectPath, setRedirectPath] = useOutletContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm();
  const [displayDeleteBtn, setDisplayDeleteBtn] = useState("inherit");
  const [fromWhere, setFromWhere] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [pet, setPet] = useState({});
  const [loading, setLoading] = useState(true);
  const [prevPhotoUrl, setPrevPhotoUrl] = useState("");
  const [saveBtnConfig, setSaveBtnConfig] = useState("");
  const [photoUrl, setPhotoUrl] = useState(catIcon);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const photoInputEl = useRef(null);

  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  let [querys] = useSearchParams();
  //watch input change
  useEffect(() => {
    const subscription = watch(() => (appContext.edited = true));
    return () => subscription.unsubscribe();
  }, [watch]);
  //fetch pet data; set different display for different page
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      if (location.pathname.indexOf("/petlist/edit") >= 0) {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/${params.petid}?storeId=${appContext.storeId}`, {
          headers: new Headers({
            Authorization: "Bearer " + appContext.idToken,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            if (data !== null) {
              setPet(data);
              setPrevPhotoUrl(data.photoUrl);
              setLoading(false);
              if (data.photoUrl !== "") {
                fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/photo/${data.photoUrl}`, {
                  headers: new Headers({
                    Authorization: "Bearer " + appContext.idToken,
                  }),
                })
                  .then((response) => {
                    if (response.ok) {
                      return response.blob();
                    }
                  })
                  .then(function (myBlob) {
                    var objectURL = URL.createObjectURL(myBlob);
                    setPhotoUrl(objectURL);
                  })
                  .catch(function (error) {
                    console.error("fetch pet photo error: ", error.message);
                  });
              }
            }
          })
          .catch((error) => {
            console.error("Fetch due date error", error);
          });
        setDisplayDeleteBtn("inherit");
        setFromWhere("寵物清單");
        setCurrentLocation("編輯");
        setSaveBtnConfig({
          method: "PUT",
          url: `${process.env.REACT_APP_API_URL}/api/v1/pet/${params.petid}?storeId=${appContext.storeId}`,
        });
      } else if (location.pathname.indexOf("/petlist/create") >= 0) {
        setDisplayDeleteBtn("none");
        setFromWhere("寵物清單");
        setCurrentLocation("新增");
        setLoading(false);
        setSaveBtnConfig({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/v1/pet?storeId=${appContext.storeId}`,
        });
      } else if (location.pathname.indexOf("/couplelist/createchild") >= 0) {
        var _fatherChipNumber = querys.get("fatherChipNumber");
        var _motherChipNumber = querys.get("motherChipNumber");
        setPet({ fatherChipNumber: _fatherChipNumber, motherChipNumber: _motherChipNumber });
        setDisplayDeleteBtn("none");
        setFromWhere("配種清單");
        setCurrentLocation("新增子嗣");
        setLoading(false);
        setSaveBtnConfig({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/v1/pet?storeId=${appContext.storeId}`,
        });
      }
    }
  }, []);
  //when refresh but not saved, show alert
  useEffect(() => {
    function handleUnload(e) {
      if (appContext.edited) {
        // e.preventDefault();
        // window.confirm("尚未儲存，確定要離開嗎？");
        e.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);
  function SaveAlert({ showSaveAlert, handleClose }) {
    return (
      <Dialog open={showSaveAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">{"尚未儲存，是否儲存?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">尚未儲存，是否儲存?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleSubmit(onSubmit)();
              handleClose();
            }}
            variant="contained"
            autoFocus
          >
            儲存
          </Button>
          <Button
            onClick={() => {
              appContext.edited = false;
              handleClose();
              var _redirectPath = redirectPath;
              setRedirectPath("");
              navigate(_redirectPath);
            }}
          >
            直接離開
          </Button>
          <Button onClick={handleClose}>繼續編輯</Button>
        </DialogActions>
      </Dialog>
    );
  }
  function isValidDate(data, property, name, errMessage) {
    if (data[property] instanceof Date && !isNaN(data[property])) {
      data[property] = format(data[property], "yyyy-MM-dd");
    } else if (typeof data[property] === "string" && data[property] !== "") {
      return false;
    } else if (data[property] === null) {
      data[property] = "";
    } else {
      setError(name, { type: "date", message: errMessage });
      return true;
    }
    // return d instanceof Date && !isNaN(d);
    return false;
  }
  
  const onSubmit = (data) => {
    // console.debug(data);
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      //normalize date
      var error = false;
      error |= isValidDate(data, "birthday", "birthday", "生日格式錯誤");
      error |= isValidDate(data, "dueDate", "dueDate", "待產日格式錯誤");
      error |= isValidDate(data, "rabiesVaccination", "rabiesVaccination", "狂犬病接種日格式錯誤");
      error |= isValidDate(data, "generalVaccination", "generalVaccination", "預防針接種日格式錯誤");
      if (error) {
        return;
      }
      data.import === "是" ? (data.import = true) : (data.import = false);
      data.importPrice = parseInt(data.importPrice);
      fetch(saveBtnConfig.url, {
        method: saveBtnConfig.method,
        body: JSON.stringify(data),
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((response) => {
          // console.debug(response);
          // console.debug("data.photoUrl: ", data.photoUrl);
          //photo changed, upload photo
          if (typeof data.photoUrl === "string" && data.photoUrl !== prevPhotoUrl) {
            var file = photoInputEl.current.files[0];
            // console.debug("file before compress: ", file)
            //compress image before upload
            new Compressor(file, {
              quality: 0.6,
              maxWidth: 500,
              maxHeight: 500,
              convertTypes: ["image/webp"],
              success(result) {
                // console.debug("file after compress: ", result)
                const formData = new FormData();
                formData.append("photoName", result, result.name);
                fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/photo/${response.petid}?storeId=${appContext.storeId}`, {
                  method: "POST",
                  body: formData,
                  headers: new Headers({
                    Authorization: "Bearer " + appContext.idToken,
                  }),
                })
                  .then((response) => response.json())
                  .then((response) => {
                    // console.debug("Success:", response);
                    appContext.edited = false;
                    if (redirectPath !== "") {
                      var _redirectPath = redirectPath;
                      setRedirectPath("");
                      navigate(_redirectPath);
                      return;
                    }
                    if (from === "coupleList") {
                      navigate("/dashboard/petlist", { replace: true });
                      return;
                    }
                    navigate(-1);
                    return;
                  })
                  .catch((error) => console.error("Error:", error));
              },
              error(err) {
                console.error(err.message);
              },
            });
          } else {
            var from = querys.get("from");
            appContext.edited = false;
            if (redirectPath !== "") {
              var _redirectPath = redirectPath;
              setRedirectPath("");
              navigate(_redirectPath);
              return;
            }
            if (from === "coupleList") {
              navigate("/dashboard/petlist", { replace: true });
              return;
            }
            navigate(-1);
            return;
          }
        })
        .catch((error) => {
          console.error("Post pet error", error);
        });
    }
  };
  const handleFileChange = (event) => {
    // console.debug("file change", event.target.files[0]);
    appContext.edited = true;
    setPhotoUrl(URL.createObjectURL(event.target.files[0]));
  };
  const handleDeletePet = () => {
    var petid = params.petid;
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/pet/${petid}?storeId=${appContext.storeId}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            var from = querys.get("from");
            appContext.edited = false;
            if (from === "petlist") {
              navigate(-1, { replace: true });
            } else if (from === "petdetail") {
              navigate(-2, { replace: true });
            }
          }
        })
        .catch((error) => {
          console.error("Delete pet error", error);
        });
    }
  };
  zhTW.options.weekStartsOn = 0;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
      <Container sx={{ pb: 3 }}>
        <Helmet>
          <title>寵物編輯 - 寵理才</title>
        </Helmet>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
          <Grid item xs={4} sm={8} md={12}>
            <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon fontSize="medium" />}>
              <Link
                underline="hover"
                href="#"
                onClick={() => {
                  if (!appContext.edited) {
                    navigate("/dashboard/petlist");
                  } else {
                    setRedirectPath("/dashboard/petlist");
                    setShowSaveAlert(true);
                  }
                }}
              >
                <FormatListBulletedIcon sx={{mr: 1}}/>
                {fromWhere}
              </Link>
              <Typography>{currentLocation}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
              <Box sx={{ position: "relative" }}>
                <Avatar alt="cat profile" src={photoUrl} sx={{ width: { xs: 80, sm: 100, lg: 120 }, height: { xs: 80, sm: 100, lg: 120 } }}></Avatar>
                <Controller
                  name="photoUrl"
                  control={control}
                  render={({ field }) => (
                    <IconButton
                      {...field}
                      aria-label="edit cat profile"
                      edge="start"
                      //onClick={() => {}}
                      component="label"
                      sx={{
                        mr: 2,
                        color: "var(--color-surface)",
                        bgcolor: "var(--color-on-surface)",
                        "&:hover": { bgcolor: "#ccc" },
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: 0,
                        width: { xs: "30px", lg: "40px" },
                        height: { xs: "30px", lg: "40px" },
                      }}
                    >
                      <EditIcon />
                      <input ref={photoInputEl} type="file" hidden onChange={handleFileChange} />
                      {/* <input type="file" hidden /> */}
                    </IconButton>
                  )}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                <Button variant="outlined" onClick={handleSubmit(onSubmit)} startIcon={<SaveIcon />}>
                  儲存
                </Button>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ display: displayDeleteBtn }}
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setShowDeleteAlert(true);
                  }}
                >
                  刪除
                </Button>
              </Box>
              <SaveAlert
                showSaveAlert={showSaveAlert}
                handleClose={() => {
                  setShowSaveAlert(false);
                }}
              />
              <DeleteAlert
                showDeleteAlert={showDeleteAlert}
                handleDelete={handleDeletePet}
                handleClose={() => {
                  setShowDeleteAlert(false);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Container
              sx={{
                bgcolor: "var(--color-surface)",
                width: { xs: 282, sm: 564 },
                padding: 2,
                borderRadius: "8px",
              }}
            >
              {Object.values(errors).map((error) => (
                <Alert key={error.message} severity="error" sx={{ mb: 2 }}>
                  {error?.message}
                </Alert>
              ))}
              {loading ? (
                <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="rectangular" width={210} height={118} />
                </Box>
              ) : (
                <form
                  id="pet-edit-form"
                  //onSubmit={handleSubmit(onSubmit)}
                  style={{
                    display: "flex",
                    gap: "16px",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <ControlledTextField
                    name="name"
                    label="名字"
                    control={control}
                    defaultValue={!!pet.name ? pet.name : ""}
                    rules={{ maxLength: { value: 20, message: "寵物名稱長度超過20字" } }}
                  />
                  <ControlledTextField
                    name="chipNumber"
                    label="晶片號碼"
                    control={control}
                    defaultValue={!!pet.chipNumber ? pet.chipNumber : ""}
                  />
                  <ControlledTextField
                    name="breed"
                    label="品種"
                    control={control}
                    defaultValue={!!pet.breed ? pet.breed : ""}
                    rules={{ maxLength: { value: 20, message: "品種長度超過20字" } }}
                  />
                  <UncontrolledDatePicker name="birthday" label="生日" control={control} defaultValue={!!pet.birthday ? pet.birthday : null} />
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue={!!pet.gender ? pet.gender : "公"}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">性別</InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="性別"
                          sx={{ width: "250px", "& fieldset": { borderColor: "rgb(192, 192, 192)" } }}
                        >
                          <MenuItem value={"公"}>公</MenuItem>
                          <MenuItem value={"母"}>母</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <ControlledTextField
                    name="color"
                    label="顏色"
                    control={control}
                    defaultValue={!!pet.color ? pet.color : ""}
                    rules={{ maxLength: { value: 20, message: "顏色長度超過20字" } }}
                  />
                  <ControlledTextField
                    name="fatherChipNumber"
                    label="父晶片號"
                    control={control}
                    defaultValue={!!pet.fatherChipNumber ? pet.fatherChipNumber : ""}
                  />
                  <ControlledTextField
                    name="motherChipNumber"
                    label="母晶片號"
                    control={control}
                    defaultValue={!!pet.motherChipNumber ? pet.motherChipNumber : ""}
                  />
                  <Controller
                    name="import"
                    control={control}
                    defaultValue={pet.import ? "是" : "否"}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">是否進口</InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="是否進口"
                          sx={{ width: "250px", "& fieldset": { borderColor: "rgb(192, 192, 192)" } }}
                        >
                          <MenuItem value={"是"}>是</MenuItem>
                          <MenuItem value={"否"}>否</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <ControlledTextField
                    name="importPrice"
                    label="進口金額"
                    control={control}
                    defaultValue={!!pet.importPrice ? pet.importPrice : ""}
                    rules={{ maxLength: { value: 10, message: "進口金額長度超過10位數" } }}
                  />
                  <UncontrolledDatePicker
                    name="rabiesVaccination"
                    label="狂犬病接種日"
                    control={control}
                    defaultValue={!!pet.rabiesVaccination ? pet.rabiesVaccination : null}
                  />
                  <UncontrolledDatePicker
                    name="generalVaccination"
                    label="預防針接種日"
                    control={control}
                    defaultValue={!!pet.generalVaccination ? pet.generalVaccination : null}
                  />
                  <UncontrolledDatePicker name="dueDate" label="待產日" control={control} defaultValue={!!pet.dueDate ? pet.dueDate : null} />
                  <Box display={{ xs: "none", sm: "block" }} width={{ xs: 0, sm: "250px" }} />
                  <ControlledTextField
                    name="comment"
                    label="備註"
                    sx={{ width: "516px", height: "auto" }}
                    control={control}
                    multiline={true}
                    defaultValue={!!pet.comment ? pet.comment : ""}
                    rules={{ maxLength: { value: 200, message: "備註長度超過200字" } }}
                  />
                  {/* <input type="submit" /> */}
                </form>
              )}
            </Container>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
}

export default PetEdit;
