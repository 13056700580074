import { createContext } from "react";
export const BreadcrumbsStyle = {
  color: "var(--color-on-surface)",
  fontSize: "16px",
  "& .MuiLink-root": {
    color: "var(--color-on-surface)",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiBreadcrumbs-li": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiTypography-root": {
    fontSize: "16px",
  },
};
export const AppContext = createContext({ signedIn: false, BreadcrumbsStyle: BreadcrumbsStyle });
