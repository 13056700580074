import { useState, useContext, useEffect } from "react";
import { Box, Link, Container, Grid, Typography, IconButton, Breadcrumbs, CircularProgress } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from "@mui/material";
import { Button, TextField, Paper, Menu, MenuItem, ListItemText, ListItemIcon, Skeleton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import zhTW from "date-fns/locale/zh-TW";
import { compareAsc, add } from "date-fns";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DownloadIcon from "@mui/icons-material/Download";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import DeleteAlert from "../component/DeleteAlert";
import GenerateContract from "../component/Contract";

function createData(id, name, chipNumber, totalPrice, saleDate, buyer) {
  return { id, name, chipNumber, totalPrice, saleDate, buyer };
}

// const rows = [
//   createData(1, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(2, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(3, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(4, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(5, "小花", "小強", "11111111111111", "22222222222222"),
//   createData(6, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(7, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(8, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(9, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(10, "志強", "春嬌", "11111111111111", "22222222222222"),
//   createData(11, "志強", "春嬌", "11111111111111", "22222222222222"),
// ];
const tableCellStyle = {
  fontSize: "16px",
  color: "var(--color-on-surface)",
  borderBottom: "1px solid var(--color-primary)",
  padding: { xs: 2 },
  whiteSpace: "nowrap",
};

const tablePaginationStyle = {
  height: "40px",
  // bgcolor:"var(--color-surface)",
  "& .MuiTablePagination-spacer": {
    flex: "0 1 auto",
  },
  "& .MuiTablePagination-toolbar": {
    height: "40px",
    minHeight: "40px",
    justifyContent: "flex-end",
  },
  "& .MuiTablePagination-selectLabel": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiInputBase-root": {
    marginRight: { xs: "16px", md: "32px" },
  },
  "& .MuiTablePagination-select": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    lineHeight: "32px",
    padding: "0px",
  },
  "& .MuiTablePagination-selectIcon": {
    color: "var(--color-on-surface)",
    fontSize: "24px",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "var(--color-on-surface)",
    fontSize: "16px",
    margin: "0px",
  },
  "& .MuiTablePagination-actions": {
    color: "var(--color-on-surface)",
    ml: { xs: "8px", md: "16px" },
  },
  "& .MuiTablePagination-actions .MuiSvgIcon-root": {
    fontSize: "24px",
  },
};

function TradeList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = useState(matches ? 10 : 5);
  const [page, setPage] = useState(0);
  const [currentTradeId, setCurrentTradeId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [unfilteredRows, setUnfilteredRows] = useState([]);
  const appContext = useContext(AppContext);
  let navigate = useNavigate();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [search, setSearch] = useState({ text: "", start: null, end: null });
  const [processingDownload, setProcessingDownload] = useState(false);
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/trades?storeId=${appContext.storeId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data !== null) {
            var tempRows = [];
            for (var trade of data) {
              tempRows.push(createData(trade.id, trade.name, trade.chipNumber, trade.totalPrice, trade.payDate, trade.buyer));
            }
            setUnfilteredRows(tempRows);
            setRows(tempRows);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Fetch trade list error", error);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    // console.debug("begin search", search);
    var start = search.start === null ? 0 : search.start;
    var end = search.end === null ? add(new Date(), { days: 1 }) : add(search.end, { days: 1 });
    // console.debug("start", start);
    // console.debug("end", end);
    var tempRows = unfilteredRows.filter(
      (row) =>
        row.name.toLowerCase().includes(search.text.toLowerCase()) ||
        row.chipNumber.includes(search.text) ||
        row.buyer.toLowerCase().includes(search.text.toLowerCase())
    );
    tempRows = tempRows.filter((row) => compareAsc(new Date(row.saleDate), start) >= 0 && compareAsc(new Date(row.saleDate), end) <= 0);
    setRows(tempRows);
    setPage(0);
  }, [search]);
  const handleMenuClick = (event, id) => {
    setCurrentTradeId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setCurrentTradeId(null);
    setAnchorEl(null);
  };
  const handleViewTrade = () => {
    var id = currentTradeId;
    setCurrentTradeId(null);
    setAnchorEl(null);
    navigate(`/dashboard/tradelist/${id}`);
  };
  const handleEditTrade = () => {
    var id = currentTradeId;
    setCurrentTradeId(null);
    setAnchorEl(null);
    navigate(`/dashboard/tradelist/edit/${id}?from=tradelist`);
  };
  const handleDownloadContract = () => {
    var id = currentTradeId;
    setCurrentTradeId(null);
    setProcessingDownload(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/trade/${id}?storeId=${appContext.storeId}`, {
      headers: new Headers({
        Authorization: "Bearer " + appContext.idToken,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        setAnchorEl(null);
        setProcessingDownload(false);
      })
      .then((data) => {
        if (data !== null) {
          GenerateContract(data, appContext.store, setProcessingDownload, setAnchorEl);
          return;
        }
        setAnchorEl(null);
        setProcessingDownload(false);
      })
      .catch((error) => {
        setAnchorEl(null);
        setProcessingDownload(false);
        console.error("Fetch trade error", error);
      });
  };
  const handleDeleteTrade = () => {
    var tradeId = currentTradeId;
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/trade/${tradeId}?storeId=${appContext.storeId}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          var tempRows = rows.filter((row) => row.id !== tradeId);
          setRows(tempRows);
          var tempunfilteredRows = unfilteredRows.filter((row) => row.id !== tradeId);
          setUnfilteredRows(tempunfilteredRows);
        })
        .catch((error) => {
          console.error("Delete trade error", error);
        });
    }
    setCurrentTradeId(null);
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCreate = () => {
    navigate(`/dashboard/tradelist/create`);
  };
  //   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  zhTW.options.weekStartsOn = 0;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
      <Container sx={{ pb: 3 }}>
        <Helmet>
          <title>買賣紀錄 - 寵理才</title>
        </Helmet>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
          <Grid item xs={4} sm={8} md={12}>
            <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle}>
              <Link underline="none">
                <ReceiptLongIcon sx={{ mr: 1 }} />
                買賣紀錄
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap " }}>
              <Button variant="outlined" onClick={handleCreate} startIcon={<AddCircleIcon />}>
                新增
              </Button>
              <Box id="search-bar">
                <TextField
                  id="search-input-field"
                  label="名稱、晶片號碼或買受人"
                  variant="outlined"
                  InputLabelProps={{ size: "small" }}
                  color="primary"
                  onChange={(event) => {
                    setSearch({ ...search, text: event.target.value });
                  }}
                  sx={{
                    width: "250px",
                    height: "40px",
                    fontSize: "12px",
                    "& label.MuiInputLabel-root": {
                      color: "#aaa",
                    },
                    "& label.Mui-focused": {
                      color: "var(--color-primary)",
                    },
                    "& .MuiInputBase-input": { padding: 1, color: "var(--color-on-surface)" },
                    "& .MuiOutlinedInput-root": {
                      color: "var(--color-on-surface)",
                      "& fieldset": {
                        borderColor: "#aaa",
                      },
                      "&:hover fieldset": {
                        borderColor: "var(--color-on-surface)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "var(--color-primary)",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", gap: "4px" }}>
                <DesktopDatePicker
                  label="起始日期"
                  openTo="day"
                  views={["year", "month", "day"]}
                  inputFormat="yyyy-MM-dd"
                  value={search.start}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        width: { xs: "150px", sm: "200px", md: "250px" },
                        height: "40px",
                        "& fieldset": { borderColor: "rgb(192, 192, 192)" },
                        "& .MuiInputBase-input": { padding: "8px 0px 8px 14px" },
                      }}
                      InputLabelProps={{ size: "small" }}
                      {...params}
                    />
                  )}
                  onChange={(value, keyboardInputValue) => {
                    setSearch({ ...search, start: value });
                  }}
                />
                <Typography sx={{ color: "var(--color-on-surface)", lineHeight: "40px" }}>～</Typography>
                <DesktopDatePicker
                  label="結束日期"
                  openTo="day"
                  views={["year", "month", "day"]}
                  inputFormat="yyyy-MM-dd"
                  value={search.end}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        width: { xs: "150px", sm: "200px", md: "250px" },
                        height: "40px",
                        "& fieldset": { borderColor: "rgb(192, 192, 192)" },
                        "& .MuiInputBase-input": { padding: "8px 0px 8px 14px" },
                      }}
                      InputLabelProps={{ size: "small" }}
                      {...params}
                    />
                  )}
                  onChange={(value, keyboardInputValue) => {
                    setSearch({ ...search, end: value });
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            {loading ? (
              <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={210} height={118} />
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "var(--color-surface)",
                  borderRadius: "8px",
                  // minWidth: "300px",
                  margin: "0px auto",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    backgroundColor: "var(--color-surface)",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <Menu
                    id="pet-item-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{
                      "& .MuiMenu-paper": { backgroundColor: "var(--color-surface-variant)", color: "var(--color-on-surface)" },
                      "& .MuiListItemIcon-root": { color: "var(--color-on-surface)" },
                    }}
                  >
                    <MenuItem onClick={handleViewTrade}>
                      <ListItemIcon>
                        <ManageSearchIcon />
                      </ListItemIcon>
                      <ListItemText>檢視</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleEditTrade}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText>編輯</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setShowDeleteAlert(true);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText>刪除</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDownloadContract}>
                      {processingDownload ? (
                        <CircularProgress sx={{ color: "var(--color-primary)" }} size={30} />
                      ) : (
                        <>
                          <ListItemIcon>
                            <DownloadIcon />
                          </ListItemIcon>
                          <ListItemText>下載契約</ListItemText>
                        </>
                      )}
                    </MenuItem>
                  </Menu>
                  <DeleteAlert
                    showDeleteAlert={showDeleteAlert}
                    handleDelete={handleDeleteTrade}
                    handleClose={() => {
                      handleMenuClose();
                      setShowDeleteAlert(false);
                    }}
                  />
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="none" sx={{ ...tableCellStyle, maxWidth: "30px" }}></TableCell>
                        <TableCell sx={{ ...tableCellStyle, minWidth: "100px" }}>名字</TableCell>
                        <TableCell sx={tableCellStyle}>晶片號碼</TableCell>
                        <TableCell sx={tableCellStyle}>總價</TableCell>
                        <TableCell sx={tableCellStyle}>售出日期</TableCell>
                        <TableCell sx={tableCellStyle}>買受人</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.id}>
                          <TableCell sx={{ ...tableCellStyle, width: "30px", pr: { xs: 0 } }}>
                            <IconButton
                              color="inherit"
                              aria-label="open drawer"
                              edge="start"
                              onClick={(e) => handleMenuClick(e, row.id)}
                              sx={{ mr: 0 }}
                            >
                              <MoreHorizIcon sx={{ width: "24px", height: "24px" }} />
                            </IconButton>
                          </TableCell>
                          <TableCell sx={{ ...tableCellStyle, minWidth: "100px" }}>{row.name}</TableCell>
                          <TableCell sx={{ ...tableCellStyle, fontFamily: "monospace" }}>{row.chipNumber}</TableCell>
                          <TableCell sx={{ ...tableCellStyle, fontFamily: "monospace" }}>{row.totalPrice}</TableCell>
                          <TableCell sx={tableCellStyle}>{row.saleDate}</TableCell>
                          <TableCell sx={tableCellStyle}>{row.buyer}</TableCell>
                        </TableRow>
                      ))}
                      {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 80 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={5} sx={{ borderBottom: "1px solid var(--color-primary)" }} />
                    </TableRow>
                  )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="每頁顯示"
                  labelDisplayedRows={({ from, to, count }) => `${from} - ${to} / ${count}`}
                  sx={tablePaginationStyle}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
}

export default TradeList;
