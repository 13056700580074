import { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import { Helmet } from "react-helmet";
import { AppContext } from "../AppContext";
const tableCellStyle = {
  fontSize: "16px",
  color: "var(--color-on-surface)",
  borderBottom: "1px solid #888",
  padding: 2,
};

function createData(key, value) {
  return { key, value };
}

// const rows = [createData("父晶片號", "11111111111111"), createData("母晶片號", "22222222222222")];

function CoupleDetail() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const appContext = useContext(AppContext);
  let navigate = useNavigate();
  let params = useParams();
  useEffect(() => {
    if (appContext.idToken !== "" && appContext.storeId !== "") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/coupleList/${params.coupleid}?storeId=${appContext.storeId}`, {
        headers: new Headers({
          Authorization: "Bearer " + appContext.idToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((coupleResult) => {
          if (coupleResult !== null) {
            var tempRows = [];
            tempRows.push(createData("父晶片號", coupleResult.fatherChipNumber));
            tempRows.push(createData("母晶片號", coupleResult.motherChipNumber));
            setRows(tempRows);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Fetch due date error", error);
        });
    }
    // setDueDates([new Date(2022, 6, 4), new Date(2022, 6, 2), new Date(2022, 7, 1)]); // for testing
  }, []);
  function handleEdit() {
    navigate(`/dashboard/couplelist/edit/${params.coupleid}?from=coupleDetail`);
  }
  return (
    <Container sx={{ pb: 3 }}>
      <Helmet>
        <title>配種檢視 - 寵理才</title>
      </Helmet>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
        <Grid item xs={4} sm={8} md={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={appContext.BreadcrumbsStyle} separator={<NavigateNextIcon />}>
            <Link underline="hover" href="/dashboard/couplelist">
              <FeaturedPlayListIcon sx={{ mr: 1 }} />
              配種清單
            </Link>
            <Typography>檢視</Typography>
          </Breadcrumbs>
        </Grid>
        {loading ? (
          <Box sx={{ width: 300, display: "flex", flexDirection: "column", gap: 2 }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={118} />
          </Box>
        ) : (
          <Grid item xs={4} sm={8} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
              <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEdit}>
                  修改
                </Button>
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "var(--color-surface)",
                borderRadius: "8px",
                maxWidth: "500px",
                minWidth: "300px",
                margin: "0px auto",
              }}
            >
              <Table sx={{}} aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" sx={tableCellStyle}>
                        {row.key}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, fontFamily: "monospace" }}>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default CoupleDetail;
